.sender {
  width: 50%;
}
.receiver {
  width: 50%;
  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

.replyBox .modal-content {
  width: 75%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: auto;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}
.upld_img {
  position: relative;
  width: 60px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.chat-leftsidebar {
  background-color: #eff2f7;
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: 0 2px 4px #000;
}
@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 360px;
  }
}
.chat-leftsidebar .chat-leftsidebar-nav .nav {
  background-color: #74788d;
}
.chat-leftsidebar .chat-leftsidebar-nav .nav .nav-link.active {
  background-color: #eff2f7;
  color: #5664d2;
}
.chat-noti-dropdown.active:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ff3d60;
  border-radius: 50%;
  right: 0;
}
.chat-noti-dropdown .btn {
  padding: 0px;
  box-shadow: none;
  font-size: 16px;
}
.chat-search-box .form-control {
  border: 0;
}
.chat-list {
  margin: 0;
}
.chat-list li.active a {
  background-color: #74788d;
}
.chat-list li a {
  display: block;
  padding: 14px 16px;
  color: #74788d;
  transition: all 0.4s;
  border-top: 1px solid #eff2f7;
  border-radius: 4px;
}
.chat-list li a:hover {
  background-color: #74788d;
}
.chat-list li .user-img {
  position: relative;
}
.chat-list li .user-img .user-status {
  width: 10px;
  height: 10px;
  background-color: #74788d;
  border-radius: 50%;
  border: 2px solid #eff2f7;
  position: absolute;
  right: 0;
  bottom: 0;
}
.chat-list li .user-img.online .user-status {
  background-color: #1cbb8c;
}
.user-chat {
  background-color: #eff2f7;
  box-shadow: 0 2px 4px #000;
}
.user-chat .user-chat-border {
  border-bottom: 1px solid #eff2f7;
}
.user-chat-nav .dropdown .nav-btn {
  height: 36px;
  width: 36px;
  line-height: 36px;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
}
.user-chat-nav .dropdown .dropdown-menu {
  box-shadow: 0 2px 4px #000;
  border: 1px solid #eff2f7;
}
.chat-conversation li {
  clear: both;
}
.chat-conversation .chat-avatar {
  float: left;
  margin-right: 8px;
}
.chat-conversation .chat-avatar img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.chat-conversation .chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
}
.chat-conversation .chat-day-title .title {
  background-color: #eff2f7;
  position: relative;
  z-index: 1;
  padding: 3px 16px;
  border-radius: 30px;
}
.chat-conversation .chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #eff2f7;
  top: 10px;
}
.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}
.chat-conversation .conversation-list {
  margin-bottom: 24px;
  display: inline-block;
  position: relative;
}
.chat-conversation .conversation-list .ctext-wrap {
  overflow: hidden;
}
.chat-conversation .conversation-list .ctext-wrap .conversation-name {
  font-weight: 500;
  margin-bottom: 7px;
}
.chat-conversation .conversation-list .ctext-wrap-content {
  padding: 12px 16px;
  background-color: #5664d2;
  border-radius: 0.25rem;
  color: #fff;
}
.chat-conversation .conversation-list .chat-time {
  margin-top: 7px;
  font-size: 12px;
  text-align: right;
}
.chat-conversation .right .conversation-list {
  float: right;
}
.chat-conversation .right .conversation-list .conversation-name {
  text-align: right;
}
.chat-conversation .right .conversation-list .ctext-wrap-content {
  background-color: #eff2f7;
  text-align: right;
  color: #74788d;
}
.chat-conversation .right .conversation-list .chat-time {
  text-align: left;
}
.chat-input-section {
  background-color: #f9f9fa;
  border-radius: 0.25rem;
}
.chat-input {
  background-color: #eaedf3 !important;
  border-color: #eff2f7 !important;
}
.chat-input-links {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.chat-input-links li a {
  font-size: 16px;
  line-height: 36px;
  padding: 0px 4px;
  display: inline-block;
}
@media (max-width: 575.98px) {
  .chat-send {
    min-width: auto;
  }
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}
.avatar-sm {
  height: 3rem;
  width: 3rem;
}
/* .chat_head {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
.chat-input-section {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 72px;
  z-index: 999;
  background: #fff;
} */
.chat-conversation li p {
  color: #fff;
}
.chat-conversation li.right p {
  color: #000;
}
.chat-conversation .conversation-list .ctext-wrap-content img {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  object-position: center;
}
