@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sender {
  width: 50%;
}
.receiver {
  width: 50%;
  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

.replyBox .modal-content {
  width: 75%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: auto;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}
.upld_img {
  position: relative;
  width: 60px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.chat-leftsidebar {
	 background-color: #eff2f7;
	 border-radius: 0.25rem 0 0 0.25rem;
	 box-shadow: 0 2px 4px #000;
}
 @media (min-width: 992px) {
	 .chat-leftsidebar {
		 min-width: 360px;
	}
}
 .chat-leftsidebar .chat-leftsidebar-nav .nav {
	 background-color: #74788d;
}
 .chat-leftsidebar .chat-leftsidebar-nav .nav .nav-link.active {
	 background-color: #eff2f7;
	 color: #5664d2;
}
 .chat-noti-dropdown.active:before {
	 content: "";
	 position: absolute;
	 width: 8px;
	 height: 8px;
	 background-color: #ff3d60;
	 border-radius: 50%;
	 right: 0;
}
 .chat-noti-dropdown .btn {
	 padding: 0px;
	 box-shadow: none;
	 font-size: 16px;
}
 .chat-search-box .form-control {
	 border: 0;
}
 .chat-list {
	 margin: 0;
}
 .chat-list li.active a {
	 background-color: #74788d;
}
 .chat-list li a {
	 display: block;
	 padding: 14px 16px;
	 color: #74788d;
	 transition: all 0.4s;
	 border-top: 1px solid #eff2f7;
	 border-radius: 4px;
}
 .chat-list li a:hover {
	 background-color: #74788d;
}
 .chat-list li .user-img {
	 position: relative;
}
 .chat-list li .user-img .user-status {
	 width: 10px;
	 height: 10px;
	 background-color: #74788d;
	 border-radius: 50%;
	 border: 2px solid #eff2f7;
	 position: absolute;
	 right: 0;
	 bottom: 0;
}
 .chat-list li .user-img.online .user-status {
	 background-color: #1cbb8c;
}
 .user-chat {
	 background-color: #eff2f7;
	 box-shadow: 0 2px 4px #000;
}
 .user-chat .user-chat-border {
	 border-bottom: 1px solid #eff2f7;
}
 .user-chat-nav .dropdown .nav-btn {
	 height: 36px;
	 width: 36px;
	 line-height: 36px;
	 box-shadow: none;
	 padding: 0;
	 font-size: 20px;
	 border-radius: 50%;
}
 .user-chat-nav .dropdown .dropdown-menu {
	 box-shadow: 0 2px 4px #000;
	 border: 1px solid #eff2f7;
}
 .chat-conversation li {
	 clear: both;
}
 .chat-conversation .chat-avatar {
	 float: left;
	 margin-right: 8px;
}
 .chat-conversation .chat-avatar img {
	 width: 36px;
	 height: 36px;
	 border-radius: 50%;
}
 .chat-conversation .chat-day-title {
	 position: relative;
	 text-align: center;
	 margin-bottom: 24px;
	 margin-top: 12px;
}
 .chat-conversation .chat-day-title .title {
	 background-color: #eff2f7;
	 position: relative;
	 z-index: 1;
	 padding: 3px 16px;
	 border-radius: 30px;
}
 .chat-conversation .chat-day-title:before {
	 content: "";
	 position: absolute;
	 width: 100%;
	 height: 1px;
	 left: 0;
	 right: 0;
	 background-color: #eff2f7;
	 top: 10px;
}
 .chat-conversation .chat-day-title .badge {
	 font-size: 12px;
}
 .chat-conversation .conversation-list {
	 margin-bottom: 24px;
	 display: inline-block;
	 position: relative;
}
 .chat-conversation .conversation-list .ctext-wrap {
	 overflow: hidden;
}
 .chat-conversation .conversation-list .ctext-wrap .conversation-name {
	 font-weight: 500;
	 margin-bottom: 7px;
}
 .chat-conversation .conversation-list .ctext-wrap-content {
	 padding: 12px 16px;
	 background-color: #5664d2;
	 border-radius: 0.25rem;
	 color: #fff;
}
 .chat-conversation .conversation-list .chat-time {
	 margin-top: 7px;
	 font-size: 12px;
	 text-align: right;
}
 .chat-conversation .right .conversation-list {
	 float: right;
}
 .chat-conversation .right .conversation-list .conversation-name {
	 text-align: right;
}
 .chat-conversation .right .conversation-list .ctext-wrap-content {
	 background-color: #eff2f7;
	 text-align: right;
	 color: #74788d;
}
 .chat-conversation .right .conversation-list .chat-time {
	 text-align: left;
}
 .chat-input-section {
	 background-color: #f9f9fa;
	 border-radius: 0.25rem;
}
 .chat-input {
	 background-color: #eaedf3 !important;
	 border-color: #eff2f7 !important;
}
 .chat-input-links {
	 position: absolute;
	 left: 16px;
	 top: 50%;
	 -webkit-transform: translateY(-50%);
	         transform: translateY(-50%);
}
 .chat-input-links li a {
	 font-size: 16px;
	 line-height: 36px;
	 padding: 0px 4px;
	 display: inline-block;
}
 @media (max-width: 575.98px) {
	 .chat-send {
		 min-width: auto;
	}
}
 
.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}
.avatar-sm {
  height: 3rem;
  width: 3rem;
}
/* .chat_head {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
.chat-input-section {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 72px;
  z-index: 999;
  background: #fff;
} */
.sender {
  width: 50%;
}
.receiver {
  width: 50%;
  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

.replyBox .modal-content {
  width: 75%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: auto;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}
.upld_img {
  position: relative;
  width: 60px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.chat-leftsidebar {
  background-color: #eff2f7;
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: 0 2px 4px #000;
}
@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 360px;
  }
}
.chat-leftsidebar .chat-leftsidebar-nav .nav {
  background-color: #74788d;
}
.chat-leftsidebar .chat-leftsidebar-nav .nav .nav-link.active {
  background-color: #eff2f7;
  color: #5664d2;
}
.chat-noti-dropdown.active:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ff3d60;
  border-radius: 50%;
  right: 0;
}
.chat-noti-dropdown .btn {
  padding: 0px;
  box-shadow: none;
  font-size: 16px;
}
.chat-search-box .form-control {
  border: 0;
}
.chat-list {
  margin: 0;
}
.chat-list li.active a {
  background-color: #74788d;
}
.chat-list li a {
  display: block;
  padding: 14px 16px;
  color: #74788d;
  transition: all 0.4s;
  border-top: 1px solid #eff2f7;
  border-radius: 4px;
}
.chat-list li a:hover {
  background-color: #74788d;
}
.chat-list li .user-img {
  position: relative;
}
.chat-list li .user-img .user-status {
  width: 10px;
  height: 10px;
  background-color: #74788d;
  border-radius: 50%;
  border: 2px solid #eff2f7;
  position: absolute;
  right: 0;
  bottom: 0;
}
.chat-list li .user-img.online .user-status {
  background-color: #1cbb8c;
}
.user-chat {
  background-color: #eff2f7;
  box-shadow: 0 2px 4px #000;
}
.user-chat .user-chat-border {
  border-bottom: 1px solid #eff2f7;
}
.user-chat-nav .dropdown .nav-btn {
  height: 36px;
  width: 36px;
  line-height: 36px;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
}
.user-chat-nav .dropdown .dropdown-menu {
  box-shadow: 0 2px 4px #000;
  border: 1px solid #eff2f7;
}
.chat-conversation li {
  clear: both;
}
.chat-conversation .chat-avatar {
  float: left;
  margin-right: 8px;
}
.chat-conversation .chat-avatar img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.chat-conversation .chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
}
.chat-conversation .chat-day-title .title {
  background-color: #eff2f7;
  position: relative;
  z-index: 1;
  padding: 3px 16px;
  border-radius: 30px;
}
.chat-conversation .chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #eff2f7;
  top: 10px;
}
.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}
.chat-conversation .conversation-list {
  margin-bottom: 24px;
  display: inline-block;
  position: relative;
}
.chat-conversation .conversation-list .ctext-wrap {
  overflow: hidden;
}
.chat-conversation .conversation-list .ctext-wrap .conversation-name {
  font-weight: 500;
  margin-bottom: 7px;
}
.chat-conversation .conversation-list .ctext-wrap-content {
  padding: 12px 16px;
  background-color: #5664d2;
  border-radius: 0.25rem;
  color: #fff;
}
.chat-conversation .conversation-list .chat-time {
  margin-top: 7px;
  font-size: 12px;
  text-align: right;
}
.chat-conversation .right .conversation-list {
  float: right;
}
.chat-conversation .right .conversation-list .conversation-name {
  text-align: right;
}
.chat-conversation .right .conversation-list .ctext-wrap-content {
  background-color: #eff2f7;
  text-align: right;
  color: #74788d;
}
.chat-conversation .right .conversation-list .chat-time {
  text-align: left;
}
.chat-input-section {
  background-color: #f9f9fa;
  border-radius: 0.25rem;
}
.chat-input {
  background-color: #eaedf3 !important;
  border-color: #eff2f7 !important;
}
.chat-input-links {
  position: absolute;
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.chat-input-links li a {
  font-size: 16px;
  line-height: 36px;
  padding: 0px 4px;
  display: inline-block;
}
@media (max-width: 575.98px) {
  .chat-send {
    min-width: auto;
  }
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}
.avatar-sm {
  height: 3rem;
  width: 3rem;
}
/* .chat_head {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
.chat-input-section {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 72px;
  z-index: 999;
  background: #fff;
} */
.chat-conversation li p {
  color: #fff;
}
.chat-conversation li.right p {
  color: #000;
}
.chat-conversation .conversation-list .ctext-wrap-content img {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  object-position: center;
}

html,
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  /* font-family: "Poppins", sans-serif; */
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  /* border-radius: 50%; */
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
a {
  text-decoration: none;
}
button:hover,
a:hover {
  opacity: 1;
}
button:focus,
input:focus,
.btn:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}
button.btn{
  background-color: #a024b7;
}
@media (min-width: 768px) {
  .pl-md-4_5,
  .px-md-4_5 {
    padding-left: 2.15rem !important;
    padding-right: 2.15rem !important;
  }
}
body > iframe {
  display: none;
}
.head_menu,
.banner .slider_text p,
.banner .slider_text button,
.service_head h6,
.faq_head_home h6,
.service .service_part p,
.service .service_part button,
.provider_sec .slider2_body p,
.provider_sec .slider2_body button,
.provider_head h6,
.blog_text p,
.Blog .blog_btn,
.question_head h6,
.answer .ans_text h6,
.ans_btn,
.footer_text h5,
.footer_text p,
.footer_text .foot_menu,
.filter_part .form-control,
.Pagination_sec .page-link,
.other_area .casting_sec p,
.back_to p,
.counter_sec span,
.service_list .service_list_text h6,
.other_area .casting_sec button,
.provide_tab,
.review_sort .form-control,
.latest_box p,
.blog_details_text p,
.blog_text h6,
.answer .ans_text h6,
.answer button,
.contact p,
.contact button,
.login_text button,
.app_details .google,
.app_details .fb,
.app_details .apple,
.cart_box_right button,
.profile_drop .dropdown-item,
.profile .edit_btn,
.edit_head .choose_file,
.close-body .close_btn1,
.close-body .close_btn2,
.login_btn,
.Spell .h5 {
  font-family: "Roboto", sans-serif;
}
.Product_sec .product_text h6,
.Product_list .product_list_text h6,
.product_box2 .product_list_text2 h6 {
  font-family: "Montserrat Alternates", sans-serif;
}
.form-group {
  margin-bottom: 0rem;
}
.relative {
  position: relative;
}
/* ******Header******** */
.nav_logo h2 {
  font-style: normal;
  font-weight: 500;
  font-weight: 500;
  font-size: 1.4em;
  color: #0a0e17;
  margin: 7px 0;
}
.navbar img.logo {
  height: 28px;
  width: 7rem;
}
.head_menu {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  color: #000;
}
.head_menu.active {
  color: #a024b7;
}
.head_menu:focus {
  outline: none;
  box-shadow: none;
}
.head_menu:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #a024b7;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
.head_menu:hover::before,
.head_menu.active::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
/* service menu dropdown */
.head_menu .dropdown-menu {
  position: absolute;
  /* z-index: 10; */
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  left: 0;
  top: 50px;
  max-height: 450px;
  max-width: 900px;
  overflow: auto;
}
._header_Dropmenus {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}
._head_trig:hover ._header_Dropmenus {
  opacity: 1;
  z-index: 10;
  visibility: visible;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .head_menu .dropdown-menu {
    max-width: 400px;
  }
  ._designoverview{
    flex-direction: column;
  }
}
@media screen and (max-width: 450px) {
  .head_menu .dropdown-menu {
    max-width: 300px;
  }
}
.head_menu .dropdown-list-items {
  display: flex;
  transition: all 0.5s;
}
.head_menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-left: 4px solid transparent;
}
.head_menu .dropdown_menu_title {
  font-size: 15px;
  font-weight: 600;
  background: none;
  text-transform: capitalize;
  border-bottom: 1px solid #a024b7;
  color: #a024b7;
  border-left: 4px solid transparent !important;
  background-color: #e9ecef !important;
}
.head_menu .dropdown-item:focus,
.head_menu .dropdown-item:hover {
  color: #a024b7 !important;
  background-color: #e9ecef;
  border-left: 4px solid #a024b7;
}

.header_search {
  border: none;
  border-bottom: 1px solid #e4e4e4;
  border-radius: 0%;
}
.header-cart i {
  color: #000;
  font-size: 21px;
  font-weight: 900;
}
.profile_drop .dropdown-item:hover i{
  color: #fff;
}
/* .header-cart i.far.fa-user {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
} */
.header-cart a:hover i {
  color: #a024b7;
}
.login_btn {
  height: 42px;
  width: 110px;
  background: #a024b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
}
.login_btn:hover {
  color: #fff;
  background: #000;
}
.login_btn:focus {
  outline: none;
  box-shadow: none;
}
.contact_div_index {
  background: rgba(79, 25, 99, 1);
  padding: 2.3rem 0;
}
.contact_div_index_text_div p {
  color: white;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}
.contact_div_index_btn_div .contact_div_index_btn {
  background-color: #a024b7;
  color: white;
  transition: all 0.3s;
}
.contact_div_index_btn_div .contact_div_index_btn:hover {
  background-color: #000;
}
/* avail area */
.offer_bg {
  position: relative;
  background-color: rgba(79, 25, 99, 1);
  padding: 1rem;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
}
.offer_banner {
  position: absolute;
  background-color: #3fc001;
  left: -30px;
  width: 100px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  -webkit-transform: rotate(314deg);
  transform: rotate(314deg);
  overflow: hidden;
  top: 10px;
}
.offer_img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 5rem;
  height: 5rem;
  margin: auto;
  margin-left: 6px;
}
.offer_btn {
  text-align: end;
}
.offer_btn a {
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 25px;
  font-size: 12px;
}
.offer_title h5 {
  color: white;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
}
.offer_title h5 span {
  font-weight: 600;
  letter-spacing: 1px;
}
/* seller breadcumb */
.page_top_section {
  background: #ededed;
  padding: 6rem 0;
  box-shadow: 0px -5px 27px 0px rgb(0 0 0 / 15%) inset;
}
.page_top {
  justify-content: center;
  align-items: center;
  display: flex;
}
.page_top_title {
  text-align: center;
}
.page_top_title h3 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #a024b7;
  margin-bottom: 10px;
}
.page_top_title p {
  margin-bottom: 0;
  font-size: 16px;
  color: #666;
  text-transform: capitalize;
}
/* seller breadcumb */
.dropdown-item:hover{
  background-color:#e086f094 ;
  color: #fff;
}
.profile_drop .dropdown-item:hover{
  color: #fff;
}
.profile_drop .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0%;
  z-index: 100;
  min-width: 17rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background: #ffffff;
  margin-top: 0.75em;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border: none;
  opacity: 0;
  /* display:block; */
  
}
.profile_drop:hover .dropdown-menu {
  opacity: 1;
  display: block;
}

.profile_drop .dropdown-toggle::after {
  margin: auto;
}
.profile_drop .dropdown-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #0a0e17;
  padding: 0.35rem 1.5rem;
  cursor: pointer;
}
.search_icon i {
  cursor: pointer;
  color: #0a0e17;
  font-size: 21px;
}
.serch_bar.form-group {
  width: 95%;
  position: relative;
}
.serch_bar .search {
  background: #ededed;
  border-radius: 100px;
}
.serch_bar .form-control {
  background: #ededed;
  border-radius: 100px;
  margin: auto;
}
.serch_bar .bg-search {
  background: rgba(79, 25, 99, 1);
  border-radius: 0px 100px 100px 0px;
  /* position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%; */
  padding: 5px 1em;
  cursor: pointer;
}
.bg-search i {
  color: #ffffff;
}
.search_modal {
  background: #ffffff;
  box-shadow: 0px 34px 100px rgb(0 0 0 / 20%);
  border-radius: 0 0 5px 5px;
  width: 100%;
  max-width: 100%;
  position: absolute;
  z-index: 1;
  margin-top: 10px;
}
.search_modal .modal-dialog {
  max-width: 100%;
}
.search_modal_content {
  height: 250px;
  overflow-y: auto;
}
.search_modal-body .search-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.62;
  color: rgb(148, 152, 163);
  cursor: pointer;
}
.search_modal-body .search-text:hover {
  background: rgb(202, 202, 202);
}
.search_modal-body span {
  color: #0a0e17;
}
.profile_drop .dropdown-toggle {
  font-family: "Roboto", sans-serif;
}
.header-cart {
  position: relative;
}
.header-cart p {
  position: absolute;
  background: #a024b7;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  top: 50%;
}
.profile_drop .dropdown-menu {
  right: 0;
 left:-46%;
  
}

/* ********header end******* */
.choose_file {
  background: #a3aaba;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 15px;
  color: #ffffff;
  width: 120px;
  height: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.choose_file .input_file {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
/* ******Slider ****************/
.banner .slider_ban {
  background-repeat: no-repeat;
  width: 100%;

  height: 75vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.slider_text {
  position: relative;
  z-index: 5;
}
.slider_ban .slider_text {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: auto;
  height: 100%;
  max-width: 600px;
  margin: auto 0;
}
.banner .slider_text h1 {
  font-style: normal;
  /* font-weight: bold; */
  font-size: 44px;
  line-height: 1.2;
  color: #000;
  font-family: "AlegreyaRegular";
}
.banner .slider_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 20.8px;
  line-height: 30px;
  color: #000;
}
.banner .slider_text button {
  /* border: 2px solid #ffffff; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  border-radius: 10px;
  min-width: 170px;
  background-color: #a024b7;
  text-align: center;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}
.banner .slider_text button:hover {
  background-color: black;
  color: white;
  /* border: 2px solid #fff; */
}
.banner .slider_text button:focus {
  outline: none;
  box-shadow: none;
}
/* .banner .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #01052403;
} */
.banner .slick-prev,
.banner .slick-next {
  font-size: 0px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 39px;
  height: 43px;
  padding: 0;
  cursor: pointer;
  /* color: #212529; */
  z-index: 10;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.banner .slick-prev {
  left: 35px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnARMIIxOMXTdcAAACRklEQVRYw82XP2gTcRTHv++XDFKMYmmwFUuNrYVSkoh0cHDIUC2hIZNc0aGiVdxEERwcrBGhTkUK0kVt66BItFvNH5o/ikMc/NMgCmpMbbXakILSpCBy91y8xXDk0uQu+c6Pd58PPx7vHVCnTHn3Op40OZ0zovNH2JnJTK92TYSb0+nbK44tc486OvT2sdYLnG6I35SMxXgIh3jNbkfoH1BQfLQEQiFmtDP39hIBRMxa/US9wPEaTViz20sK32M7vtjtwSAQDIqyfIYL3JP2XZpr7umhs+IC5aNRTXAvzsG9sUFDuEokSZIESJIsl+tPRoMrx3m3+BqP4ycm8LS1VRP8Ji+KPp/vxFhmfGAlkdD7nZoLmAVecwGzwdVUPQO6wb/xGJaLxVqBq9n0C1QKzpdxFGd8vpOUIS8lk9WCb1qgUcArFmg0cDVlZ2CUgVEWQnnJ0yIZjWqCB/gxJgsFLohdZPN6jQbXLaA7D9FNz5hph/xLuaUoRoPrFggQECBFIY/sx9vBQVzjV3Qqny8pfEdOfmCz4Q8VaTgSmeJODrHHY7RAxUM8M+I4HbrvcnFCjNPFWAxX6ADfbWkpKWy0IW5Ukao3cb1FanZK1Euk5sec2SKGndNmiRgmYJaI4QJq7hS690T2u90Wl5zm1fl5TZEU2vE8l8tOfkqkrre1qXtIq69p/8QjWz8sDrxZWJDTFhft7O/XXIhxdCGcy5UDV2PaC/wf9UWs2+QUy7OzOIgX3Le+Tt/5vPWY3z+c/Zw9fGRpqVyfvzCZ2ZYdCRm+AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAxLTE5VDA4OjM1OjE5KzAwOjAwxCG1EgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMS0xOVQwODozNToxOSswMDowMLV8Da4AAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDEtMTlUMDg6MzU6MTkrMDA6MDDiaSxxAAAAAElFTkSuQmCC);
}
.banner .slick-next {
  right: 35px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnARMIICwRFkmiAAACTklEQVRYw2NgIBLMn6IyadstX98FSsqfdki+fr1QXMVs+8SzZxf3KvfteKSiQqw51AaMRHvgv/K1HYEnTjAuZPT7n2ZuDpd4//8kY87Tp8z/GBYy7HdwiC2+W+Qhd+cOvTzARILaHf9PnTiBISrIaP5/irT0XyaGeAbHAwfoHSNEe+DXTIElohtKSxk8GfIYZ23YgNMjyows/0UOH14Uplq2VUhTk9YeIDoJwcDMGcZGZ06zsrIHfkx407ZqFcN2hkn/0wICMBQKMOQx2L94wbSM8ck/GSenuFW3u7zfXb8+4B4YbB4h2wODxSMUe2CgPUJKKYQXpGecPWdi+vs31wE2iU8R4eE4M/sHhkkMByUk/kX9l2F6sm8fpZmdajGADlat1Fq1aiUb2zeHXy/4VqxcSasYoVoMoIOw8GthYeG/fsFj5BUDC8P3TZswFMJixPJfObPg7t0LXqpM2nZLWXnAPYDhEXm2ps+/Q0NxegRajzAGMxgzMk+aNGg8QGtAcw/A88LDX3W8rKtXM4gx/GHg9PPDUAhtU/1fy3D2/9+8vAH3AMzhX8N+beKds2oVToc3M3xikH7yBNYYTBC/k+eldvcusfZQvRRCdzjjQoYTDEX+/jgdnvW/glHA0ZHcVizVPEBvh1PNAwPlcIo9MNAOJ9sDg8XhJHtgsDkcBoguRr/9/7mU93N3N06Hw/rGdHI4yR74H87QzrgVqTOP7vAB6tQzE6swQFRYPWrTy5eMHf+nMwW6ujLYM95kOHf9OvM3hmeM23x86O1wGAAAffjC9CXU5UkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDEtMTlUMDg6MzI6NDQrMDA6MDAPysHPAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAxLTE5VDA4OjMyOjQ0KzAwOjAwfpd5cwAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wMS0xOVQwODozMjo0NCswMDowMCmCWKwAAAAASUVORK5CYII=);
}
.banner .slick-prev:before,
.banner .slick-next:before {
  content: "";
  color: #ffffff;
}

.banner .slick-dots li button:before {
  font-family: "slick";
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: -42px;
  left: 0;
  width: 18px;
  height: 18px;
  content: "•";
  text-align: center;
  opacity: 0.45;
  color: #a024b7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.banner .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #a024b7;
}
/* **********Slider1 end******* */

/* *********service********* */
.service {
  border: none;
  height: auto;
  width: 94%;
  margin: auto;
  min-height: 422px;
  background: #ffffff;
  box-shadow: 0 0 10px -5px #000;
  border-radius: 20px;
  border-bottom: 15px solid #a024b7;
}
/* .service:hover {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(10, 14, 23, 0.15);
  border-radius: 10px;
} */
.service .service_part h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #0a0e17;
  height: 50px;
  margin-bottom: 10px !important;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}
.service .service_part p {
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  min-height: 50px;
  color: #212529;
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.service .card-body {
  padding: 10px;
}
.view-btn {
  background: #a024b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-width: 120px;
  line-height: 30px;
  color: #ffffff;
}

.view-btn:hover {
  color: #ffffff;
  background-color: black;
}
.service .service_part button,
.red_btn {
  /* border: 2px solid #0a0e17; */
  /* font-style: normal;
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  min-width: 120px; */
  /* font-family: "Poppins", sans-serif; */
  background-color: #a024b7;
  margin:5px 0 0 0 !important;
 
  border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    min-width: 96px;
    line-height: 22px;
    color: #ffffff;
}
.service .service_part button:hover,
.red_btn:hover {
  color: #a024b7;
  background-color: #fff;
  border-color: #a024b7;
}
.service .service_part button:focus {
  outline: none;
  box-shadow: none;
}
.service .service_part img {
  width: 100%;
  /* max-width: 80px;
  height: 80px; */
  margin-bottom: 1rem;
  object-fit: contain;
}
/* .service:hover .service_part button {
  background: #a024b7;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
} */
.service_head h6 {
  max-width: 140px;
  border-bottom: 2px solid #a024b7;
  margin: auto;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #0a0e17;
}
.service_head h3 {
  font-style: normal;
 font-weight: 400;
 font-size: 32px;
    color: #a024b7;
 line-height: 38px;
 text-align: center;
  font-family: "Roboto Slab", serif;
}
.home-service .service_head h3{
  font-family: "Roboto Slab", serif !important;
  margin-top: 6px;
  margin-bottom: 2rem;
  font-size: 32px;
  color:  #212529;
  font-weight: 400;
}
.Provider .service_head h3{
  font-family: "Roboto Slab", serif !important;
  margin-top: 6px;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: 400;
}
.Products .service_head h3{
  font-family: "Roboto Slab", serif !important;
  margin-top: 6px;
  margin-bottom: 2rem;
  font-size: 32px;
  color:  #212529;
  font-weight: 400;
}
.Blog-- .service_head h3{
  font-family: "Roboto Slab", serif !important;
  margin-top: 6px;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: 400;
}
.service_head h3 span{
  color:#a024b7;
}
.service_head h3 span {
  color: #a024b7;
  font-family: "Roboto Slab", serif;
}
.faq_head_home h6 {
  width: 175px;
  border-bottom: 3px solid #a024b7;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}
.faq_head_home h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  color: #0a0e17;
}
.faq_head_home h3 span {
  color: #a024b7;
}
.Provider h6,
.Provider h3,
.Blog h6,
.Blog h3 {
  color: #fff;
}
/* *******service end***** */
.more-pell h1{
  font-style: normal;
 font-weight: 600;
 font-size: 40px;
 line-height: 38px;
 color: #a024b7;
 text-align: center;
}
.Provider {
  /* background-image: url("./Assets/Images/Vector.png"); */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 817px; */
  padding: 1rem 0;
  background: rgba(79, 25, 99, 1);
}
.provider_head h6 {
  width: 175px;
  border-bottom: 1px solid #ffffff;
  margin: auto;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}
.provider_head h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
.provider_sec .slider2_part {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(10, 14, 23, 0.15);
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 100%;
  max-height: 460px;
}
.bg_lineCover {
  border: 10px solid transparent;
  border-image: url(/static/media/provider_bg.53a105d6.svg) 10 stretch;
}
.provider_sec .slider2_part .slider2_body img {
  height: 208px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.provider_sec .slider2_body h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #a024b7;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Roboto", sans-serif;
}
.provider_sec .slider2_body p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 0;
  text-align: center;
  color: #0a0e17;
}
.provider_sec .slider2_body i {
  color: #f2c94c;
  width: 16px;
  height: 16px;
}
.provider_sec .slider2_part span {
  font-size: 15px;
}
.provider_sec .slider2_body button {
  background: #a024b7;
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  min-width: 110px;
  /* font-family: "Poppins", sans-serif; */
  color: #ffffff;
}
.provider_sec .slider2_body button:hover {
  background-color: black;
  color: white;
}
.provider_sec .slider2_body button:focus {
  outline: none;
  box-shadow: none;
}
/* .provider_sec .prev {
  position: relative;
  background-image: url("./Assets/Images/Ellipse\ 1.png");
} */
.provider_sec .slick-prev,
.provider_sec .slick-next {
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 19px;
  height: 19px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  color: #0000;
  text-align: center;
  line-height: 19px;
  background: #000;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 15;
}
.provider_sec .slick-prev {
 /* left: 7px; */
 left: 0;
  /* background-image: url("./Assets/Images/Provider_left_arrow.png"); */
}
.provider_sec .slick-next {
/*  right: 7px; */
  right: 0;
  /* background-image: url("./Assets/Images/provider_right_arrow.png"); */
}
.provider_sec .slick-prev:before,
.provider_sec .slick-next:before {
  font-family: "slick";
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  opacity: 1;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.provider_sec .slick-slide {
  display: block;
  /* float: left; */
  height: 100%;
  min-height: 1px;
  padding: 0.75em;
}
.slick-next:before {
  content: " ";
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
  position: absolute;
  border: 2px solid #fff;
  border-color: #fff #fff #0000 #0000;
  -webkit-transform: translate(-65%, -50%) rotate(45deg);
          transform: translate(-65%, -50%) rotate(45deg);
}
.slick-prev:before {
  content: " ";
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
  position: absolute;
  border: 2px solid #fff;
  border-color: #0000 #0000 #fff #fff;
  -webkit-transform: translate(-25%, -50%) rotate(45deg);
          transform: translate(-25%, -50%) rotate(45deg);
}
/* **********provider end******** */
/* ********casting*********** */
.casting_area .casting_sec {
  border: none;
  width: 94%;
  margin: auto;
  height: auto;
/*  min-height: 350px; 
  min-height: 500px; */
  min-height:350px;
 /* padding-top: 2.5rem !important; */
  padding-top: 0 !important;
  background: #ffffff;
  box-shadow: 0 0 10px -5px #000;
  border-radius: 20px;
  border-bottom: 15px solid #a024b7;
}

.casting_area .casting_text img {
  width: 100%;
/*  max-width: 80px;
  height: 80px;
*/  
/*  margin-bottom: 1rem; */
  margin-bottom: 0;
  object-fit: fill;
  height: 200px;
}

.casting_area .casting_text h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #0a0e17;
  height: 30px;
  overflow: hidden;
  margin-bottom: 10px !important;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}
.casting_area .casting_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
 /* min-height: 92px; */
  height: 50px;
  color: #0a0e17;
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.casting_area .casting_text button {
  font-style: normal;
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  min-width: 120px;
  /* font-family: "Poppins", sans-serif; */
  background-color: #a024b7;
  color: white;
}
.casting_area .casting_text button:hover {
  background-color: black;
  color: white;
}
.casting_area .casting_text button:focus {
  outline: none;
  box-shadow: none;
}

/* ************casting end*********** */
/* **************Provider2*********** */
.Provider2 {
  background-image: url("/static/media/provider2 bg.34f9a4d7.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  /* height: 140vh; */
}
/* ************Provider2 end*********** */

/* ***************Product************* */
.Product_sec .product_box {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgb(0 0 0 / 10%),
    0px 4.25733px 11.019px rgb(0 0 0 / 5%);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 385px;
  border: none;
}
/* ************************product updated********************************* */
.product_box2 {
  background: #ffffff;
  box-shadow: 0 0 18px #e0e0e0;
  border-radius: 15px;
  width: 100%;
  height: auto;
  /* min-height: 285px; */
  border: none;
}
.product_box2 .card-img-top {
  width: 100%;
  height: 252px;
}

.product_box2 .product_list_text2 h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #0a0e17;
  height: 64px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  width: 55%;
  min-width: 50px;
}
.product_box2 .rating {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #0a0e17;
  display: flex;
}
.product_box2 .rating i {
  color: #f2c94c;
}
.product_box2 p {
  color: #a024b7;
  font-size: 18px;
}
.product_box2 .Product_buy_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 24px;
  background: #fff;
  color: blue;
  cursor: pointer;
}
.product_box2 .cart {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #585354bf;
  cursor: pointer;
}
/* ************************product updated end********************************* */
.Product_sec .product_text h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;

  color: #0a0e17;
}
.Product_sec .product_text p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #0a0e17;
}
.Product_sec .rating {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #0a0e17;
}
.Product_sec .rating i {
  color: #f2c94c;
}
.Product_sec .cart {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #a024b7;
  cursor: pointer;
}
.Products button {
  background: #a024b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-width: 120px;
  line-height: 30px;
  color: #ffffff;
}
.Products button:hover {
  background: #000;
  color: #fff;
}
.Products button:focus {
  outline: none;
  box-shadow: none;
}
/* **********product end************ */
/* **********Blog************* */
.Blog-- {
  background: rgba(79, 25, 99, 1);
  width: 100%;
  padding: 1rem 0;
  /* height: 110vh; */
}
.Blog-- h6 {
  color: #ffffff;
}
.Blog-- h3 {
  color: #ffffff;
}
/* *******************************blog updated************************** */
.Blog-- .blog_btn {
  background: #a024b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-width: 120px;
  line-height: 30px;
  color: #ffffff;
}
.Blog-- .blog_btn:hover {
  background-color: black;
  color: white;
}
.blog_box2 {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(6, 7, 9, 0.34);
  border-radius: 64px 64px 20px 20px;
  width: 100%;
  margin: auto;
  height: 400px;
  border: none;
  padding: 1px;
}
.blog_box2 .card-img-top {
  border-radius: 64px 64px 0px 0px;
  height: 208px;
  object-fit: cover;
  object-position: top;
}
.seller_service .img-fit {
  object-fit: cover;
  object-position: top;
  height: 200px;
}
.blog_box2 h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 20.8px;
  line-height: 30px;
  color: #000;
}
/* *************************************blog updated end****************************** */
.blog_box {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
  width: 400px;
  margin: auto;
  display: table;
  height: auto;
  border: none;
}
.blog_text h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  /* line-height: 1.25; */
  color: #0a0e17;
  min-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Roboto", sans-serif;
}
.blog_text h5:hover {
  color: #a024b7;
}
.blog_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
  color: #0a0e17;
  height: 48px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Blog .blog_btn {
  background: #a024b7;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}
.Blog button:focus {
  outline: none;
  box-shadow: none;
}
/* *********blog end****** */
/* ***********question********* */
.question_head h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #0a0e17;
  width: 175px;
  border-bottom: 1px solid #a024b7;
}
.question_head h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 34px;
  color: #0a0e17;
}
.Faq-border-bottom {
  border-bottom: 1px solid #a024b7;
}
.answer .ans_text p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0a0e17;
  width: auto;
  flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
  /* max-width: 801px; */
  /* height: 24px; */
}
.answer .ans_text i {
  color: #a024b7;
  cursor: pointer;
}
.answer .ans_text h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}
.ans_text .plus_sign {
  color: #a024b7;
}
.ans_btn {
  border: none;
  background: #a024b7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-width: 200px;
  line-height: 30px;
  color: #ffffff;
  /* font-family: "Poppins", sans-serif; */
}
.ans_btn:hover {
  background-color: black;
  color: white;
}
.ans_btn:focus {
  outline: none;
  box-shadow: none;
}
button.listclose_btn {
  background: transparent;
  border: none;
  color: red;
  right: -15px;
  top: -20px;
  z-index: 1;
  font-size: 20px;
  position: absolute;
}
/* **********question end******** */
/* ******Footer************* */
.Footer_clip {
  /* background-image: url("./Assets/Images/footer_bg.png"); */
  /* background: rgba(79, 25, 99, 1); */
  background: rgba(79, 25, 99, 1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding-top: 7.5em; */
  padding-bottom: 1em;
}
.Footer_clip h1 {
  font-size: 50px;
  font-weight: 400;
}
.footer_text h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
}
.footer_store img {
  width: 10rem;
}
.footer_hr {
  width: 4rem;
  height: 4px;
  background-color: white;
  opacity: 1;
}
.app_img {
  width: 20px;
  height: 20px;
}

.footer_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}
.footer_text .foot_menu {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.foot_menu.active,
.footer_text .foot_menu:hover {
  color: #a024b7;
}
.footer_text .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #effdff;
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-radius: 40px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.footer_text .form-group {
  position: relative;
}

.footer_text .send_btn {
 
  border-radius: 0 40px 40px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #ffffff;
  position: absolute;
  right: 0%;
  bottom: 0%;
  cursor: pointer;
}
/* .send_btn:hover{
  color: red;
} */
.footer_text .send_btn:focus {
  outline: none;
  box-shadow: none;
}
/* **********Footer end******* */

/* ***********Services*********** */
.Spell {
  background: #ededed;
}
.Spell .h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgba(79, 25, 99, 1);
}
.Spell .spell_menu {
  background: #ffffff;
  border-radius: 50px;
  font-size: 14px;
  color: #0a0e17;
}
.Services h3 {
  font-style: normal;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #0a0e17;
    font-family: "Roboto Slab", serif;
    margin: 0 auto;
}
.Services h3 span{
  font-family: "Roboto Slab", serif;
  text-transform: capitalize;
}
.filter_part .service-search {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 10px);
  padding: 0.375rem 0.3rem;
  font-size: 0.87rem;
  font-weight: 400;
  /* line-height: 1.5; */
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0a0e17;
}
.filter_part .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 10px);
  padding: 0.375rem 0.3rem;
  font-size: 0.87rem;
  font-weight: 400;
  /* line-height: 1.5; */
  border: 1px solid #a024b7;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0a0e17;
}
.filter_part .budget_btn {
  background: #a024b7;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}
.filter_part h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #a3aaba;
}
.service_list {
  background: #ffffff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  border: none;
  min-height: 360px;
}
.service_list .service_list_img {
  /* border-radius: 20px 20px 0 0;
  height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: top; */
  border-radius: 20px;
    height: 192px;
    width: 100%;
    margin: auto;
    object-fit: cover;
    object-position: top;
}
.service_list .service_list_text h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}
.service_list .service_list_text h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
  margin-bottom: 0;
}
.service_list .service_list_text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #a024b7;
}
.service_list .service_list_text p {
  font-style: normal;
  font-weight: 600;
  font-size: 1.13em;
  color: #0a0e17;
  /* height: 40px; */

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.service_list .service_list_text i {
  color: #f2c94c;
}
.service_list .pricing p {
  font-size: 14px;
  color: #6c7579;
}
.new_product_design .rating {
  font-size: 14px;
}
.new_product_design .rating i {
  color: #f2c94c;
  width: 16px;
  height: 16px;
}
.conversation-list .ctext-wrap {
  overflow: hidden;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: -5%;
  right: -12%;
  /* background-color: #ffffff; */
  border-radius: 50%;
  width: 27px;
  height: 27px;
  z-index: 0;
}
.absolute_bg {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  position: absolute;
  top: 1%;
  right: 3%;
  /* background-color: #ffffff; */
  border-radius: 50%;
  text-align: center;
  padding: 6px 0;
}
.absolute_bg:hover {
  background: #000;
}
.service_list .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  min-width: 19px !important;
  width: 19px;
  height: 19px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 5;
  background: #0000005e;
  /* background-image: url("./Assets/Images/service_list_left_arrow.png"); */
}
.service_list .slick-arrow:hover {
  background: #000;
}
.service_list .slick-prev {
  left: 0%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  border-radius: 0px 20px 20px 0px;
}
.service_list .slick-next {
  right: 0%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  border-radius: 20px 0px 0px 20px;
}
.service_list .slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #070707;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.service_list .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #070707;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
._service_Allpage .slick-prev {
  left: 0;
}
._service_Allpage .slick-next {
  right: 0;
}
.service_list.new_product_design .service_list_img {
  border-radius: 20px;
  height: 192px;
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: top;
}

/* ***********service list end******** */
/* **********pagination*********** */
.Pagination_sec .pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.Pagination_sec .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0a0e17;
  font-size: 14px;
}
.Pagination_sec .page-link.active {
  background: #ededed;
  border-radius: 4px;
}
/************ pagination end *************/
/* ************other services******* */
.other_area .casting_sec {
  border: none;
  width: 100%;
  height: 100%;
  min-height: 400px;
}
.other_area .casting_sec h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;

  color: #0a0e17;
}
.other_area .casting_sec p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #0a0e17;
}
.other_area .casting_sec button {
  border: 2px solid #0a0e17;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #0a0e17;
}
.other_area .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 0%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  /* background: transparent; */
  /* background-image: url("./Assets/Images/other_service_left_arrow.png"); */
  background-position: center;
  background-size: cover;
  z-index: 5;
}

.other_area .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  right: 0%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  /* color: transparent; */
  border: none;
  outline: none;
  /* background: transparent; */
  /* background-image: url("./Assets/Images/other_service_right_arrow.png"); */
  background-position: center;
  background-size: cover;
  z-index: 5;
}
.other_area .slick-next:before,
.other_area .slick-prev:before {
  font-family: "slick";
  font-size: 25px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ********other service end***** */

/******* service details********* */
._breaking_word table tbody tr td:last-child {
  word-break: break-all;
}
.Details .detail_pic {
  width: 100%;
  max-width: 100%;
  height: 400px;
}
.Details .detail_pic .magnifier-image {
  cursor: none;
  height: 500px;
  object-fit: cover;
  object-position: top;
}
.Details .slick-prev,
.Details .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-position: center;
  background-size: cover;
  z-index: 10;
}
.Details .slick-prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI9SURBVHgBtZZLaBNBGMe/DXmQwG4gj0PpRj0VTfEkluRoWwxCCoW2vkCopXroRQRP4lHsxYAgIr5qQZQqeEpAGkgRD+5aQXoqdS99pEsPyRy2SzYQSNL5AilLabuP7P5hGHZnvv3xn9mZ72MIIW/Aplqt1i7DMFXa1ur1usTzPDltPoOwxLkk2NFg8jwkaUunhmBqchyazabQaDTyJ0F7gunFJ/phamIcHj6YI9RpPhqNCq7B9NBvS4vQ39dXiMfjef2YBxzWblmGzLUJ2NiQspVKZcxVGErd34fJm9Mg7+1lq9XqiKuwLvA6BaqqmpVlOeQqDIVL+n7hUygYDA67DkNRGCiKMoLuLMNGR69EdjbXb5mdj8u5XFwJBQKBtCUYgpY+fxx7/fbDPytxgriKXcI0rAvKvXgpzs/n/oMFCX/+YjdgCtYLCKUoKtBbJWgI6xWEwn2jMv5BFt69yoji6rZdkF6GsLuzc8VUaujsk8ePBsCmMDO02+2y12hiqfST3Lg9Xfj6ZTGLz0+fPZfAojAVeTweYghzAoj5jjpbMwXrBdjJczSx+nw+ydKh7gLv35u5ZDYGEyp1JXAcRxxPnnqhq9+/isTv9+cQ5tpFzHJcJ2NjiYAgfGd6z+yAzvB8PhKJHNYijjvDpVv+8R0uDl5AUEE/5pgzdDM7cwebxrJsgYJKR+fYhuHHwywL6fTlzjnKXB3WwuFwiRarK7FYTDsupgMrb62DDWl08zVaFUter7dcq9VE6kY7LeAA+4b8+GAZyicAAAAASUVORK5CYII=);
  display: none !important;
}
.Details .slick-next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI5SURBVHgBtZZNaBNBFIDfbkIgC2kwyUW2SRRCxUotCpVs8VAM6sHkJPgTKNQgetBDC17Esz0J/hz8QakFIUqjUEk8tJLiQYxoPLrUXhS7q4dkD+6GRDZ/7tuD0DZpdqbZ7zIwO7Mf8+bNvGGgB8VikYtEIlFd10Msyw4xDOM2ujmggNlOEg6HY5VK5djS8gpX+PgJCoXP8EfTQFNVIGX9hwjOTh/K5XJM07T4nXsPuCdzz6h+3oktslKplJCkX/Ez56ZAkmXoJ+xmkbi6Fj956nTfRRtkiqIIsvzbXFG/wtZRJkmSv91uJ+wU/Ze5XK7E7bv3/VZD9/O7eP54bMIPhLC4KofDIWQyi5YnPXw89yWdfpogFTrdbvdQ5uUiUULMzt5awxaFyeSF7Nv8O8XKPGej0TiEB5YUGqHTCKFPFFeBBlIhY6T8o+CeYdgJ2deZidGDB3aH9g4/7zam63VFwo3r1/YJ0bHw2eRUrtdYTP2qZ2AAaEDRzPTVKIryFvaMNQ5zzevxACmkIlNmJMg3QRgDEmhEpswohutC9AiQcPlS6jCpCGFkWeb+6vrN8aMnODvvRcxGluf56i6vN38xNQl2Y17EtVptJZWarA7yPNiJKcPVGRmZW3gxD7THwLIMCQQC+VBoMLuQtk+44Vng8/lyIyP7s0tvXoEdIWU3d6AwGOTnP7xfVmamr/RV2vXdqKqqv9lsxlut1jjWOyxDWB2+UlYITH2m1yCU1ut1fAmPGuKA0QaBkn8b3woaNmLE/AAAAABJRU5ErkJggg==);
  display: none !important;
}
.Details .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: auto;
}
.Details .details_sub_text > div:first-child {
  width: 50%;
}
.Details .details_sub_text h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #0a0e17;
  margin-bottom: 0;
}
.Details .details_sub_text p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #a3aaba;
  margin-bottom: 0;
}
.Details .contact span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  color: rgba(79, 25, 99, 1);
}
.Details .rating_sec {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  color: #0a0e17;
}
.Details .rating_sec i {
  color: #f2c94c;
}
.Details .rating_sec h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #0a0e17;
}
.Details .rating_sec span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #a3aaba;
}
.Details .heading h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2;
  color: #0a0e17;
}
.Details .rupee_sec del {
  font-size: 1.2rem;
}
.Details .rupee_sec h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;

  color: #a024b7;
}
.Details .rupee_sec p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* #33D198 */

  color: #33d198;
}
.Details .rupee_sec button {
  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 4px;
}
.Details .details_text .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ededed;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Details .bottom_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 200% */

  /* Font Color */

  color: #0a0e17;
}
.Details .basket_btn button {
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #ffffff;
  width: 100%;
  max-width: 181px;
  height: 46px;
}
.Details .basket_btn button:hover {
  color: #a024b7;
  background-color: #fff !important;
  border-color: #a024b7;
}
.service_list button.cart:hover{
  border:1px solid #a024b7;
  padding: 4px;
}
.Details .basket_btn button:focus {
  outline: none;
  box-shadow: none;
}
.cart_box_right .list-group button.btn{
  background-color: transparent;
} 
.cart_box_right .input-group-append button.btn{
  color:#fff;
}
.counter_sec span {
  font-size: 18px;
}
.Highlights h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  color: #a3aaba;
}
.Highlights p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
  color: #0a0e17;
}
.Highlights i {
  color: #33d198;
}
.Highlights h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;

  color: #a3aaba;
}
.select.active {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;

  color: rgba(79, 25, 99, 1);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid rgba(79, 25, 99, 1);
}
.select {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;

  color: #a3aaba;
  cursor: pointer;
}
.review_sort h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #a3aaba;
}
.review_sort .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Font Color */

  color: #0a0e17;
}
.review_list h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #0a0e17;
}
.review_list i {
  color: #f2c94c;
}
.review_list span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  color: #a3aaba;
}
.review_list p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #0a0e17;
}
.Related h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;
  color: #0a0e17;
}
.back_to p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Secondary font color */

  color: #a3aaba !important;
}
/* **********service-details******** */
/* ****************related services*************** */
.related_services .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -41px;
  left: auto;
  right: 5%;
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAqCAYAAABGB/BgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB3ZdRDcIwFEVPUICESpgEJOBgk4ADcICEzQESkDAJkzAJow00LMvakOw+GDvJ/T5N+nrbgp69z91n8Ol8CsQ4n/YliOkR4niufLCSuIQg5IKAIiM4ISAI+oSgQkCZEPSIJqpkfvWykT1nBA4BPxO0KkGdEexZyLiHpmkQCdqMYDEO45pwfy8w76ED6R46IKBkfvWyogvM7YGsJgI7BAfqEyqMaztyxHjjI7kRLhHiSB/GM0Lc5kS5qr8iJIhuCVGNmAbD63fMBeOXynZFJ77UdxXGD+7IKvpO8glajUj6+42iad91GBBqpuFdO8UDoKX8SCqQJdgAAAAASUVORK5CYII=);
  z-index: 5;
  background-position: center;
  background-size: cover;
}
.related_services .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -41px;
  right: 2%;
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAqCAYAAABGB/BgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZhRDYMwFEVPpmASKgEJk4AD6mA4AAebA3AwCUhAAhKYA0YTliyE169bFjjJ/eGDE2i5bQHI5vRzpjnNnCsJGBbBN0HoEDOuJNMidggpNyTHFnlDFF5nhpCM7TEK1wqEBNHA9lOVCHERUYUQdzpRqJneEDUICaLWEL0Q993TEPVqUc1O7VCfTuTZqe9y7L7LERLruwIhzhCNF9LzRoS1DoV4BNywB/6GgILEU/hO4o+xOrzgQcKqDzdoDEGHSGAtvy0CXERQI8Bhl16NAJdaEKttj4DYhtsjoOCPPSQRVOyw69jlYLqWJDli/07ZjgQ/Cz4AtP02CE3EDAAAAABJRU5ErkJggg==);
  z-index: 5;
  background-position: center;
  background-size: cover;
}

/* ********Service_provider******* */
.Provider_banner {
  position: relative;
}
.provider_banner_card {
  position: relative;
  /* top: 40%;
  left: 0%;
  right: 0%;
  bottom: 0%; */
  z-index: 5;
}
.provider_banner_card .provider_shop_btn {
  background: #a3aaba;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-width: 120px;
  color: #ffffff;
}
.provider_card_img img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}
.provider_card_head p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}
.provider_card_head .icon {
  color: #f2c94c;
}
.provider_card_head span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #a3aaba;
}
.provider_card_head h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  color: #0a0e17;
}
.provider_card_head .location {
  color: #a024b7;
}
.provider_card_right p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  color: #a3aaba;
}
.provider_card_right h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 24px; */
  color: #0a0e17;
}
._cst_btn_43dsf2 button {
  font-size: 15px;
}
._cst_btn_43dsf2 button {
  font-size: 14px;
  width: 140px;
  padding-left: 0;
  padding-right: 0;
}
.provider_sec .slider2_body ._wislist_icon_465 {
  border: 0;
  padding: 0;
  min-width: 35px !important;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: #ffffff;
  position: absolute;
  top: 1%;
  right: 3%;
  border-radius: 50%;
  text-align: center;
}
.contact span {
  color: rgba(79, 25, 99, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 24px; */
}
.provider_card_text h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;

  color: #a3aaba;
}
._chrt_btns {
  border: none;
  padding: 0 5px;
  margin: 0 1rem;
  background: transparent;
}

._chrt_btns > p {
  margin: 0;
  color: #444;
  font-size: 1.5rem;
}

._chrt_btns.active {
  border-bottom: 2px solid #333;
}
.provider_card_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  color: #0a0e17;
}
.provider_card_text span {
  font-weight: bold;
}
.provider_card_text h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;

  color: #a3aaba;
  cursor: pointer;
}
.provider_banner_card .card {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
}
.Provider_service_list {
  /* margin-top: 400px; */
  margin-top: 0;
}
.Provider_service_list h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  color: #0a0e17;
}
.Provider_service_list .Search_bar {
  background: #ededed;
  border-radius: 100px;
  width: 100%;
  max-width: 200px;
}
.Provider_service_list input {
  background: #ededed;
  border-radius: 100px;
}
.Provider_service_list input::-webkit-input-placeholder {
  color: rgba(10, 14, 23, 0.3);
}
.Provider_service_list input::placeholder {
  color: rgba(10, 14, 23, 0.3);
}

.provide_tab.active {
  background: #ededed;
  border-radius: 50px;
}
.provide_tab {
  background: #ffffff;

  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 50px;
}
/* ********service provider end****** */

/* ************Product list****** */
/* .Product_list_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  margin-bottom: 0;
  color: #0a0e17;
}
.Product_list .product_list_btn {
  background: #a024b7;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.Product_list .product_list_text h6,
.product_list_text h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  font-size: 1.3em;
  color: #0a0e17;
  height: 52px;
  text-align: center;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.Product_list .product_list_text i {
  color: #f2c94c;
}
.Product_list .product_list_text .cart {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #a024b7;
  cursor: pointer;
}
.Product_list .product_list_text .rating {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}
.Product_list .product_list_text del {
  font-size: 14px;
} */
.color_varient {
  display: flex;
  padding-bottom: 10px;
}
.color_varient li {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.color_varient .yellow {
  background-color: #fdf791;
}
.color_varient .pink {
  background-color: pink;
}
.color_varient .blue {
  background-color: rgb(79, 161, 236);
}
/* **********product list end****** */

/* ***********related product***** */
.related_products .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -8%;

  left: 94%;
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAqCAYAAABGB/BgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB3ZdRDcIwFEVPUICESpgEJOBgk4ADcICEzQESkDAJkzAJow00LMvakOw+GDvJ/T5N+nrbgp69z91n8Ol8CsQ4n/YliOkR4niufLCSuIQg5IKAIiM4ISAI+oSgQkCZEPSIJqpkfvWykT1nBA4BPxO0KkGdEexZyLiHpmkQCdqMYDEO45pwfy8w76ED6R46IKBkfvWyogvM7YGsJgI7BAfqEyqMaztyxHjjI7kRLhHiSB/GM0Lc5kS5qr8iJIhuCVGNmAbD63fMBeOXynZFJ77UdxXGD+7IKvpO8glajUj6+42iad91GBBqpuFdO8UDoKX8SCqQJdgAAAAASUVORK5CYII=);
  z-index: 5;
  background-position: center;
  background-size: cover;
}
.related_products .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -35px;
  right: 1%;
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAqCAYAAABGB/BgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZhRDYMwFEVPpmASKgEJk4AD6mA4AAebA3AwCUhAAhKYA0YTliyE169bFjjJ/eGDE2i5bQHI5vRzpjnNnCsJGBbBN0HoEDOuJNMidggpNyTHFnlDFF5nhpCM7TEK1wqEBNHA9lOVCHERUYUQdzpRqJneEDUICaLWEL0Q993TEPVqUc1O7VCfTuTZqe9y7L7LERLruwIhzhCNF9LzRoS1DoV4BNywB/6GgILEU/hO4o+xOrzgQcKqDzdoDEGHSGAtvy0CXERQI8Bhl16NAJdaEKttj4DYhtsjoOCPPSQRVOyw69jlYLqWJDli/07ZjgQ/Cz4AtP02CE3EDAAAAABJRU5ErkJggg==);
  z-index: 5;
  background-position: center;
  background-size: cover;
}
/* ************related product end********** */

/* *******Blog page********** */
.Blog_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;

  color: #0a0e17;
  text-align: center;
}
.blog_ban_img img {
  position: relative;
  width: 100%;
  height: 510px;
}
.blog_ban_img {
  position: relative;
}
.blog_ban_overlay {
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -18.9%,
    rgba(0, 0, 0, 0.67) 100%
  );
  border-radius: 10px;
  height: 510px;
}
.blog_ban_overlay h6 {
  font-style: normal;
  font-weight: 900;
  font-size: 2.5em;
  max-width: 75%;
  color: #ffffff;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.subs_box {
  background: rgba(79, 25, 99, 1);
  box-shadow: 0px 0px 15px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
  width: 100%;
  min-height: 490px;
}
.subs_box h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}
.subs_box p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a3aaba;
}
.subs_box h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
  color: #ffffff;
}
.subs_box input {
  max-width: 282px;
  height: 46px;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
}
.subs_blog_btn {
  max-width: 282px;
  /* height: 100%; */

  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #ffffff;
}
.subs_blog_btn:focus {
  outline: none;
  box-shadow: none;
}
.Latest_blog h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;

  color: #0a0e17;
}
.Latest_blog h6 span {
  color: #a024b7;
}
.latest_box {
  width: 100%;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
}
.latest_box img {
  aspect-ratio: 16/12;
  width: 100%;
  border-radius: 10px;
}
.latest_box h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #a024b7;
}
.latest_box p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(10, 14, 23, 0.7);
  min-height: 48px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 52px;
  overflow: hidden;

}
.latest_box h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  min-height: 52px;
  color: #0a0e17;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.latest_box h4:hover{
  color: #a024b7;
}
.latest_box span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a3aaba;
}
.popular_box {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
}
.popular_box .popular_text h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #a024b7;
}
.popular_box .popular_text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #0a0e17;
}
.popular_box .popular_text p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a3aaba;
  cursor: pointer;
}
/* ***********blog page end*********** */

/* *************blog details**************** */
.blog-detail-head .blog-date {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #a024b7;
}
.blog-detail-head .blog_view {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Secondary font color */

  color: #a3aaba;
}
.blog-detail-head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  color: #0a0e17;
}
.blog_details_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: rgba(10, 14, 23, 0.7);
}
.blog_details_text h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;

  color: rgba(10, 14, 23, 0.7);
}
.blog_details_text span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(10, 14, 23, 0.7);
}
.related_blog .slick-prev {
  font-size: 15px !important;
  line-height: 0;
  position: absolute;
  top: 55%;

  left: -11px;
  display: block;
  /* width: 53px;
  height: 53px; */
  padding: 5px;
  /* transform: translate(0, -50%); */
  cursor: pointer;
  /* color: #212529; */
  border: none;
  outline: none;
  /* background-image: url("./Assets/Images/Provider_left_arrow.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 15;
}
.related_blog .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 55%;
  right: -11px;
  display: block;
  /* width: 53px;
  height: 53px; */
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  /* color: transparent; */
  border: none;
  outline: none;
  /* background-image: url("./Assets/Images/provider_right_arrow.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}
/* **************blog details end******* */
/* *************Faq page************* */
/* .answer {
  width: 801px;
  height: 24px;
} */
.answer button {
  width: 150px;
  height: 46px;

  background: #a024b7;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}
.faq_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #a024b7;
}
.faq_catagory .catagory_list {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  color: #0a0e17;
  cursor: pointer;
}
.faq_catagory .faq_sub_catagory li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 46px;

  color: #a3aaba;
  cursor: pointer;
  border-left: 2px solid #a3aaba;
  padding-left: 8px;
}
.faq_sub_catagory li:hover {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 46px;

  color: #a024b7;
  border-left: 3px solid #a024b7;
}
/* ***********faq end********** */

/* ***********about us************* */
.Pink_layout {
  position: relative;
}
.about_ban_img {
  position: absolute;
  top: 14%;
  bottom: 0%;
  right: 0%;
  left: 8%;
}
.about_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;

  color: #a024b7;
}

.about_ban_head h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  color: #0a0e17;
}
.about_ban_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: rgba(10, 14, 23, 0.7);
}
.about_body h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */

  /* Font Color */
  text-align: center;
  color: #0a0e17;
}
.about_card {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
  border: none;
}
.about_card .about_card_body p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: rgba(10, 14, 23, 0.7);
}
.about_card .about_card_body span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  /* identical to box height, or 189% */

  /* Secondary font color */

  color: #a3aaba;
  cursor: pointer;
}
.about_info h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */

  /* Font Color */

  color: #0a0e17;
  text-align: center;
}
/* *******about us end*********** */

/* ********site map*********** */
.Site_map_head {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  color: #0a0e17;
}
.Site_map h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;

  color: #a024b7;
}
.Site_map ul {
  padding: 5px;
}
.Site_map li {
  
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  /* identical to box height, or 125% */
  /* #2251F9 */

  color: #2251f9;
}
/* *********site-map end***** */
.seller-faq-title h3{
  font-style: normal;
 font-weight: 600;
 font-size: 40px;
 line-height: 38px;
 color: #a024b7;
}
.privacy h3{
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #a024b7;
}
.cookies h3, .return-policy h3, .condition h3, .return-policy h2, .associate.casting_area h2 {
  font-style: normal; 
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #a024b7;
}
/*********** contact-us ****************/
.contact h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #a024b7;
}
.contact p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: rgba(10, 14, 23, 0.7);
}
.contact .form-control {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 20px;
}
.contact h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  color: #0a0e17;
}
.contact button {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #ffffff;
}
.contact button:hover{
  background-color: #000;
  color:#fff;
}
.contact .contactUsButton{
  background-color: #a024b7;
  color: #ffffff;
  width: 100%;
  height: 50px;
  font-size: 18px;
}
.contact .contactUsButton:hover{
  background-color: #000;
  color:#fff;
}
.contact button:focus {
  outline: none;
  box-shadow: none;
}
.contact .contact_form_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #a3aaba;
}
/* *******contact us end********* */
/*********** log in modal************ */

.login-body h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  color: #0a0e17;
}
.login_text .form {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
}
.login_text .form-control {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
  color: #0a0e17;
}
.login_text .form-control::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #a3aaba;
}
.login_text .form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #a3aaba;
}
.login_text .form-check-input {
  width: 30px;
  height: 30px;
  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 6px;
}
.login_text .form-check-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #0a0e17;
}
.login_text h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0a0e17;
  cursor: pointer;
}
.login_text button {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  height: 46px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.login_text button:focus {
  outline: none;
  box-shadow: none;
}
.login_text button:hover {
  color: #fff;
  background: #000;
}
.LogIn_modal button.cross.close {
  position: absolute;
  right: 0;
}
.login_text p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* Font Color */
  color: #0a0e17;
}
.login_text span {
  color: #a024b7;
  cursor: pointer;
  margin-left: 4px;
}
.LogIn_modal .ok_btn {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  height: 46px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.app_details .google {
  background: #db4437;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 45px;
  /* identical to box height */
  color: #ffffff;
  width: 100%;
  height: 46px;
}
.app_details .fb {
  background: #4267b2;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 45px;
  /* identical to box height */

  color: #ffffff;
  width: 100%;
  height: 46px;
}
.app_details .apple {
  background: #0a0e17;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 45px;
  /* identical to box height */

  color: #ffffff;
  width: 100%;
  height: 46px;
}
.app_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  /* Secondary font color */

  color: #a3aaba;
}
.app_details span {
  color: #000;
}
.main_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  display: grid;
  place-items: center;
  background: #fff;
}
.main_loader > img {
  max-height: 200px;
}
.LogIn_modal {
  background: #ffffff;
  box-shadow: 0px 34px 100px rgba(0, 0, 0, 0.2);
  border-radius: 29px;
  border: none;
  width: 100%;
  height: auto;
  /* overflow-y: auto; */
  opacity: 1;
}
.loginmodal {
  overflow-y: auto;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.loginmodal::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
/* new search bar */
.form-control{
  border: 1px solid #a024b7;
}
.form-control:focus{
  border-color: #a024b7;
}
._searchE20_group {
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 2px solid #a024b7;
}
._searchE20_group ._searchE20_in {
  border: none;
  height: 40px;
  width: 237px;
  padding: 0 1rem;
  background: transparent;
  border-radius: 25px 0 0 25px;
  transition: 0.4s ease-in-out;
}
._searchE20_group ._searchE20_btn {
  border: none;
  height: 40px;
  width: 40px;
  line-height: 40px;
  padding: 0 10px;
  text-align: center;
  border-radius: 0 25px 25px 0;
  background: transparent;
  transition: 0.4s ease-in-out;
}
._searchE20_group ._searchE20_in:focus-within + ._searchE20_btn {
  color: #fff;
  background: #000;
  transition: 0.4s ease-in-out;
}

/* *******log in modal end*********** */
/* **********service dlt modal********** */
.dlt_modal {
  background: #ffffff;
  box-shadow: 0px 34px 100px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  border: none;
  width: 100%;
  height: auto;
}
.dlt_modal .dltmodal_btn {
  background: #a024b7;
  border-radius: 5px;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
/* **********service dlt modal end********** */
/* **********add cart*********** */
.check_out h3{
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #a024b7;
  font-family: "Roboto Slab", serif;
}
.cart_head h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #0a0e17;
}
.cart_head h3{
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #a024b7;
  font-family: "Roboto Slab", serif;
}
.cart_box {
  background: #ffffff;

  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
}
.cart_box h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: #0a0e17;
}
.cart_box p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 10px; */
  min-width: 70px;
  color: #888;
}
.cart_box .form-check-input {
  width: 22px;
  height: 22px;

  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 6px;
}
.cart_box label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #0a0e17;
  margin: 5px;
}
.cart_box i {
  font-size: 8px;
}
.cart_box h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;

  color: #a024b7;
}
.cart_box button {
  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 4px;
  width: 35px;
  height: 35px;
  color: #a3aaba;
  padding: 5px;
}
.cart_box .side_btn {
  width: 100%;
  border: none;
  padding: 0;
}
.cart_box .side_btn:hover p {
  color: red;
}
.cart_box li {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;

  color: #0a0e17;
}
.cart_box_right {
  background: #ffffff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.cart_box_right h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: #0a0e17;
}
.cart_box_right p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #0a0e17;
}
.cart_box_right h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #0a0e17;
}
.cart_box_right h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #a3aaba;
}
.cart_box_right .border_here {
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
}
.cart_box_right .apply_coup_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  background-color: #ffffff;
  color: #a024b7;
}

.cart_box_right .proceed_btn {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}
/* *****add card end************ */
/* **********profile****************** */
.profile h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  color: #0a0e17;
}
.profile p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #a024b7;
}
.profile .edit_btn {
  background: #a024b7;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
}
.profile .edit_btn:focus {
  outline: none;
  box-shadow: none;
}
.shop_head h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;

  color: #0a0e17;
}
.shop {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgba(0, 0, 0, 0.1),
    0px 4.25733px 11.019px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.shop h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;

  color: #0a0e17;
}
.shop p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;

  color: #0a0e17;
}
.shop i {
  color: #f2c94c;
}
.shop span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #a3aaba;
}

.img-wrap {
  position: relative;
}
.img-wrap .avatar {
  object-fit: cover;
}
.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
}
/* **********profile end******** */
/* *********edit profile********** */
.edit_head .no_file {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #a3aaba;
}
.edit_head p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  color: #0a0e17;
  min-width: 107%;
}
.edit_form_left label {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;

  color: #0a0e17;
}
.edit_form_left .form-control {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
}
.edit_form_left .form-check-input {
  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 6px;
  width: 22px;
  height: 22px;
}
.edit_form_left .form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 240% */

  /* Secondary font color */

  color: #a3aaba;
}
.edit_form_left .save_btn {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}
.edit_form_left .save_btn:focus {
  outline: none;
  box-shadow: none;
}
.edit_form_right h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 28px; */

  color: #0a0e17;
}
.edit_form_right .form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  color: #a3aaba;
}
.edit_form_right .form-control {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 6px;
}
.edit_form_right .save_btn {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}
.edit_form_right .save_btn:focus {
  outline: none;
  box-shadow: none;
}
.edit_form_right .close_btn {
  background: #a024b7;
  border-radius: 5px;
  max-height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}
.edit_form_right .close_btn:focus {
  outline: none;
  box-shadow: none;
}
.close-body h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  text-align: center;

  color: #0a0e17;
}
.close-body .close_btn1 {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #ffffff;
}
.close-body .close_btn1 {
  outline: none;
  box-shadow: none;
}
.close-body .close_btn2 {
  border: 1px solid #a3aaba;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #0a0e17;
}
.close-body .close_btn2 {
  outline: none;
  box-shadow: none;
}
.Close_modal {
  background: #ffffff;
  box-shadow: 0px 34px 100px rgba(0, 0, 0, 0.2);
  border-radius: 29px;
  border: none;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 337px;
  margin: 80px;
}
.close_bg_modal {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
/* **********edit profile end********* */

/* **********chat msg***************** */
/* width */
.chat_list .userList::-webkit-scrollbar,
.message_list::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.chat_list .userList::-webkit-scrollbar-track,
.message_list::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.chat_list .userList::-webkit-scrollbar-thumb,
.message_list::-webkit-scrollbar-thumb {
  background: rgb(211, 211, 211);
}

/* Handle on hover */
.chat_list .userList::-webkit-scrollbar-thumb:hover,
.message_list::-webkit-scrollbar-thumb:hover {
  background: rgb(192, 192, 192);
}
.message-area {
  height: 380px;
}
.message_list {
  max-height: calc(400px - 10px);
  overflow-y: overlay;
  overflow-x: hidden;
}
.message_list:hover {
  overflow-y: overlay;
}
.message_list .userSended {
  max-width: 85%;
  margin-left: auto;
  margin-right: 0;
  /* direction: rtl; */
  text-align: right;
}
.message_list .userSended .SendText {
  justify-content: flex-end;
}
.message_list .userRecieved {
  max-width: 85%;
}
.chat_list .userList {
  max-height: 100vh;
  overflow-y: hidden;
}
.chat_list .userList:hover {
  overflow-y: overlay;
}
/* chat user list */
.badge-danger{
  background-color: #a024b7;
}
.chat_list {
  border: 1px solid #a024b7;
  box-sizing: border-box;
  border-radius: 5px;
}
.chat_list .Search_bar {
  background: #ededed;
  border-radius: 100px;
}
.chat_list .form-control {
  background: #ededed;
  border-radius: 100px;
}
.chat_list h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #0a0e17;
}
.chat_list .number {
  background: #a024b7;
  width: 17px;
  height: 18px;
  line-height: 1.2;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}
.chat_list .unread {
  color: #ed4a4a;
  font-weight: bold;
  /* margin-left: 0.5em; */
}
.chat_list p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  max-width: 225px;
  color: rgba(10, 14, 23, 0.6);
}
.chat_list span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #a3aaba;
}
.chat_list .active {
  background: #f1f1f1;
  transition: 0.3s ease-in-out;
  opacity: 0.8;
}
.chat_list .highlight {
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.chat_list .highlight:last-child {
  border: none;
}
.chat_list .highlight:hover {
  background: #f1f1f1;
  transition: 0.3s ease-in-out;
  opacity: 0.8;
}
.chat_list .highlight > div > div {
  width: 100%;
}
.chat_list .highlight .user_image {
  width: 100%;
  max-width: 50px;
}
.avatar_50 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
/* *****no chat***** */
.no-chat img {
  width: 100%;
  max-width: 150px;
  height: 150px;
  margin-top: 2em;
}
.no-chat h3 {
  font-size: 2rem;
  margin-top: 1em;
}
/* *****no chat end***** */
/*  message write css */
.paper-clip {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.paper-clip .input_file {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.msg_write_area {
  position: relative;
  height: 70px;
}
aside.emoji-picker-react {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 320px;
  width: 280px;
  font-family: sans-serif;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px #efefef;
  overflow: hidden;
  position: absolute;
  /* top: -103%; */
  bottom: 100%;
  left: 0%;
}
.msg_write_area button > i {
  font-size: 1.2em;
}
.msg_write_area button > img {
  height: 24px;
}
.msg_write_area .form-control {
  resize: none;
  padding: 0;
  /* height: 52px; */
  white-space: normal;
}
.msg_write_area .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  /* line-height: 52px; */
}
.msg_write_area .form-control::-webkit-input-placeholder {
  /* Webkit */
  /* line-height: 52px; */
}
.msg_write_area .form-control:-ms-input-placeholder {
  /* IE */
  /* line-height: 52px; */
}
/* chat head css */
.chat_details .chat_head {
  background: #ededed;
  border-radius: 5px;
  padding: 13px 15px;
}
.chat_head .user_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.chat_details .chat_head h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: #0a0e17;
  margin-bottom: 0;
}
.chat_details .chat_head p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0;
  color: #a3aaba;
}
.chat_details .chat_head span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(79, 25, 99, 1);
}
.chat_details .chat_head i {
  color: #a024b7;
  font-size: 1.13em;
}
.chat_details .chat_head .call-icon {
  cursor: pointer;
}
/* call modal */
.call_modal {
  background-color: #a024b7;
  border-radius: 7%;
}
.call_modal .call-body h5 {
  color: #ffffff;
  font-size: 25px;
}
.call_modal .call-body p {
  color: #ffffff;
  font-size: 15px;
}
.call_modal .call-body .audio-img {
  width: 100%;
  max-width: 184px;
  height: 184px;
  border-radius: 50%;
}
.call_modal .receive-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  color: #ffffff;

  box-shadow: 0 0 18px 5px #44eb168f;
}

.call_modal .receive-btn:hover {
  background-color: #ffffff;
  border: 2px solid green;
  color: green;
}
.call_modal .down-part .decline-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  color: #ffffff;
  box-shadow: 0 0 18px 5px rgb(0 0 0 / 11%);
}
.call_modal .down-part .decline-btn:hover {
  background-color: #ffffff;
  border: 2px solid red;
  color: red;
}
.call_modal .down-part .down-btn {
  width: 60px;
  height: 60px;
  color: #ffffff;
  font-size: 30px;
}
.call_modal .down-part .down-btn:hover {
  color: #000;
}
.call_modal .down-part {
  border-top: 1px solid #ededed;
  padding: 0.8em 0;
  /* margin-top: 1em; */
}
.modal-dialog.video-modal {
  position: relative;
  width: 100%;
  /* margin: 0.5rem; */
  pointer-events: none;
  max-width: 716px;
  object-fit: cover;
}
/* video */
.video_modal {
  background-color: #a024b7;
  height: 100vh;
  position: relative;
}
.video_modal .call-body h5 {
  color: #ffffff;
  font-size: 25px;
}
.video_modal .call-body p {
  color: #ffffff;
  font-size: 15px;
}
.video_modal .call-body .audio-img {
  width: 100%;
  max-width: 184px;
  height: 184px;
  border-radius: 50%;
}
.video_modal .receive-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  color: #ffffff;

  box-shadow: 0 0 18px 5px #44eb168f;
}

.video_modal .receive-btn:hover {
  background-color: #ffffff;
  border: 2px solid green;
  color: green;
}
.video_modal .down-part .decline-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  color: #ffffff;
  box-shadow: 0 0 18px 5px rgb(0 0 0 / 11%);
}
.video_modal .down-part .decline-btn:hover {
  background-color: #ffffff;
  border: 2px solid red;
  color: red;
}
.video_modal .down-part .down-btn {
  width: 60px;
  height: 60px;
  color: #ffffff;
  font-size: 30px;
}
.video_modal .down-part .down-btn:hover {
  color: #000;
}
.video_modal .down-part {
  border-top: 1px solid #ededed;
  padding: 0.8em 0;
  margin-top: 5em;
  /* margin-top: 1em; */
}
._large_video {
  /* position: relative; */
  border-radius: 13px;
  width: 100vw;
  height: clamp(250px, 62%, 370px);
}
._large_video video {
  width: 100%;
  height: 100%;
  max-height: 500px;

  /* margin-top: 1em; */
}
._video_intro.d-flex {
  position: absolute;
  top: 76%;
  left: 0%;
}
._video_intro button {
  font-size: 0.85em;
  border-radius: 8px;
  background: #3a394354;
  color: #ffffff;
}
._large_video .video-img {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 85px;
  height: 85px;
}
/* video */
/* call modal end */
/* chat details */
.chat_details {
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0;
}
.chat_details .chat_body .message_text > span {
  font-style: normal;
  font-weight: 500;
  font-size: 12.5px;
  color: #9ba1af;
}
.chat_details .chat_body .message_text > p {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: #232425;
}
.chat_details .chat_card {
  background: #f1f1f1;
  border-radius: 5px;
  border: none;
}
.chat_details .chat_card .card-body {
  padding: 1.12rem;
}
.chat_details .chat_card h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1.2;
  color: #0a0e17;
}
.chat_details .chat_card h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.12em;
  line-height: 1.2;
  color: #a024b7;
  margin: auto 0;
}
.chat_details .chat_card h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.815em;
  line-height: 1.4;
  color: #0a0e17;
  opacity: 0.92;
  margin: 6px 0;
}
.chat_details .chat_card {
  font-size: 0.9em;
}
.chat_details .chat_card p {
  font-style: normal;
  font-weight: normal;
  font-size: 0.96em;
  line-height: 1.2;
  color: #0a0e17;
}
.chat_details .chat_card span {
  color: #a024b7;
}
.chat_details .chat_card .card_btn {
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.2;
  color: #ffffff;
}
.chat_details .chat_card .bg2 {
  background: #a3aaba;
}
.Img-card {
  border: none;
}
.Img-card .MsgImg {
  width: 100%;
  max-width: 200px;
  height: 200px;
  border-radius: 5%;
}

.dropdown-menu.chat-dropdown {
  position: absolute;
  top: 100%;
  left: -70px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  cursor: pointer;
}
/* chat end*************************************** */

/* *******orders************************************ */
.order_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  /* Font Color */

  color: #0a0e17;
}
.order_list {
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
}
.order_list h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  position: relative;
  color: #0a0e17;
}
._isActiveSell {
  position: relative;
}
.order_list h5 span,
._isActiveSell span {
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.order_list p {
  font-style: normal;
  font-weight: 530;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;

  /* Font Color */

  color: #0a0e17;
  text-align: left;
}
.order_list span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Secondary font color */

  color: #a3aaba;
}
.order_details {
  background: #ffffff;
  /* #EDEDED */

  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  max-width: 840px;
}
.order_pic img {
  border-radius: 10px;
  width: 106px;
  height: 98px;
}
.order_details h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* or 125% */

  /* Font Color */

  color: #0a0e17;
}
.order_details p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  word-break: break-all;
  white-space: normal;
  /* identical to box height, or 240% */

  /* Secondary font color */

  color: #a3aaba;
}
.order_details h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Dark Blue Shade */

  color: rgba(79, 25, 99, 1);
}
.order_details i {
  color: #f2c94c;
}
.order_details h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  /* Font Color */

  color: #0a0e17;
  margin-top: 10px;
}
.order_details .right h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  /* Brand Color */

  color: #a024b7;
}
.order_status h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  /* Font Color */

  color: #0a0e17;
}
.order_status i {
  color: #33d198;
}
.order_status h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  /* identical to box height, or 150% */

  /* Font Color */

  color: #0a0e17;
  margin-top: 15px;
}
.order_status p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  /* Font Color */

  color: #0a0e17;
}
.shipping h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  /* Font Color */

  color: #0a0e17;
}
.shipping h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font Color */

  color: #0a0e17;
}
.shipping p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  /* Secondary font color */

  color: #a3aaba;
}
.invoice h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  /* Font Color */

  color: #0a0e17;
}
.invoice p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font Color */

  color: #0a0e17;
}
.total p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Font Color */

  color: #0a0e17;
}
/*
  .order_list .highlight:hover {
  background: #a3aaba40;
  opacity: 0.8;
}
*/
.order_list .highlight{
  background: #a3aaba40;
  
}
.order_details .tip_box {
  display: block;
  width: 100%;
  width: 60%;
  height: calc(1.5em + 0.75rem + 16px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.order_details .submit_btn {
  background-color: #a024b7;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
.order_details .pay_btn {
  color: #a024b7;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
.order_details .cancel_btn {
  color: #a024b7;
  font-size: 18px;
  font-weight: bold;

  line-height: 20px;
}
.order_details .pay_btn:focus {
  outline: none;
  box-shadow: none;
}
/* ******************orders end ************************** */
/* ******************seller**************** */
.seller h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #0a0e17;
}
.seller h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 24px;
  color: #0a0e17;
}
.seller .tag {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin-top: 2em;

  color: #0a0e17;
}
.seller a {
  font-size: 18px;
  color: #495057;
}
.seller .service img {
  width: 100%;
  max-width: 60px;
  height: 60px;
}
.seller .service h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #0a0e17;
  max-width: 150px;
  height: 46px;
}
.seller .service h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  color: #0a0e17;
}
.seller .service p {
  font-size: 10px;
}
.seller .sell_order {
  background: #ffffff;
  box-shadow: 0 0 18px #e0e0e0;
  border-radius: 15px;
  width: 100%;
  height: auto;
  /* min-height: 285px; */
  border: none;
}
.seller .sell_order img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.seller .sell_order h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  color: #0a0e17;
}
.seller .sell_order h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #0a0e17;
}
.seller .sell_order p {
  font-size: 12px;
  max-width: 300px;
}
.table-flow {
  overflow-x: auto;
}
.seller .sell_order .acc_btn {
  width: 100%;
  background: #038403cc;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.2;
  color: #ffffff;
}
.seller .sell_order .acc_btn:focus {
  outline: none;
  box-shadow: none;
}
.seller .sell_order .reject_btn {
  width: 100%;
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.2;
  color: #ffffff;
}
.seller .sell_order .reject_btn:focus {
  outline: none;
  box-shadow: none;
}
/* ****************seller end***************************** */
/* ****************seller sec********************* */
.seller_LeftBar {
  background-color: rgba(79, 25, 99, 1);
  width: 90%;
  /* height: auto; */
  height: 100%;
  /* max-height: 100vh; */
  overflow-y: auto;
}
.seller_LeftBar h5 {
  color: #fff;
}
.seller_LeftBar p {
  color: #fff;
}
.seller_LeftBar .left_menu {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  max-width: 80%;
}
.seller_LeftBar .left_menu.active {
  color: #333;
  background-color: #ededed;
}
.seller_LeftBar .left_menu{
  margin-bottom:13px;
}
.seller_LeftBar .left_menu:focus {
  outline: none;
  box-shadow: none;
}
/* ********************subscription******************** */
.subscription h1 {
  font-weight: bold;
  color: #a024b7;
}
.subscription_sec .Subs_card {
  border: none;
  width: 100%;
  height: auto;
  min-height: 240px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(2, 4, 8, 0.26);
  border-radius: 20px;
  border-bottom: 15px solid #a024b7;
}
.subscription_sec .buy_btn {
  border: 2px solid #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  max-width: 117px;
}

.subscription_sec .buy_btn:hover {
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  background-color: #a024b7;
}
.subscription_sec p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  height: auto;
  color: #0a0e17;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.subscription_sec .buy_btn:focus {
  outline: none;
  box-shadow: none;
}

/* ********************subscription end******************** */
/* ******************subscription form**************** */
.subs-form {
  border: none;
  width: 100%;
  height: auto;
  /* min-height: 422px; */
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(2, 4, 8, 0.26);
  border-radius: 20px;
  border-bottom: 15px solid #ed4a4a;
}
.subs-form img {
  width: 100%;
  max-width: 150px;
  height: 70px;
}
.subs-form .pay-btn {
  margin: 1em;
}
.subs-form .pay-text {
  background-color: #2324255c;
  color: #ffffff;
}
.subs-form .pay-text p {
  margin: auto;
}
.subs-form .payNow-btn {
  width: 50%;
  height: 45px;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #a024b7;
}
.bBIliq {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
/* *********************withdraw method************** */
.withdraw .card {
  border: none;
  box-shadow: 0px 0px 10px 5px #adaeaf26;
}
.withdraw .form-check-input {
  margin-left: -14px;
}
.withdraw .process_btn {
  width: 100%;
  height: 45px;
  background: #a024b7;
  color: #ffffff;
  font-size: 18px;
}
.withdraw .process_btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-paid {
  width: 100%;
  height: 35px;
  background-color: #1cbb8c;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.btn-pending {
  width: 100%;
  height: 35px;
  background-color: #a024b7;
  color: #fff;
  border: none;
  border-radius: 2px;
}
/* *********************withdraw method end************** */
/* ***************seller profile*************** */
.Seller_Profile .sell_profile_head {
  background-color: rgba(79, 25, 99, 1);
  width: 100%;
  height: auto;
}
.Seller_Profile .sell_profile_head img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.Seller_Profile .sell_profile_head h5,
p {
  color: #ffffff;
}
.Seller_Profile .sell_profile_head button:focus {
  outline: none;
  box-shadow: none;
}
.Seller_Profile .seller_sec_card img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.Seller_Profile .seller_sec_card p {
  color: #000;
}
.Seller_Profile .balance_card,
.balance_card {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,2989d8+50,207cca+51,7db9e8+100;Blue+Gloss+Default */
  /* background: rgb(30, 87, 153); 
  background: -moz-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); 
  background: -webkit-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); 
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  );  */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
  /* height: 100px;
  border-radius: 2px;
  color: #000000 !important;
  border: 1px solid #aaa; */
  background-color: rgba(79, 25, 99, 1);
  color: #fff;
  block-size: 85px;
  
  width: 200px;
  height: 100px;
}
/* ***************seller profile end*************** */
/* **************seller shop*************** */
.seller_shop .seller_service {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgb(0 0 0 / 10%),
    0px 4.25733px 11.019px rgb(0 0 0 / 5%);
  border-radius: 20px;
  width: 100%;
  max-width: 300px;
  height: 100%;
  border: none;
}
.seller_shop .seller_service h5 {
  color: #0000009e;
}
.seller_shop .seller_service img {
  border-radius: 20px;
}
.seller_shop .fav_card {
  background-color: #a024b7;
  border-radius: 15px;
  color: #ffffff;
}
.seller_shop .order_card {
  background-color: #a024b7;
  border-radius: 15px;
  color: #ffffff;
}
.seller_shop .shop_create_btn {
  background-color: #e84b56;
  color: #ffffff;
  width: 80%;
  height: 50px;
}
.seller_shop .shop_create_btn:focus {
  outline: none;
  box-shadow: none;
}
.mixed-chart {
  position: relative;
  left: 0%;
}
.add_new_serv {
  position: absolute;
  right: 10px;
  top: 15px;
  text-align: center;
  color: #ffffff;
  border-radius: 6px;
  padding: 6px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  background-color: #a024b7;
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 100%;
  max-width: 181px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add_new_serv:hover {
  background: #000;
  color: #fff;
}
/* *************create shop****** */
.create_shop img {
  width: 25%;
  height: 75%;
}
.create_shop .choose_btn {
  width: 100%;
  height: 50px;
  background: #2324254f;
  min-width: 125px;
}
.create_shop .choose_btn:focus {
  outline: none;
  box-shadow: none;
}
.create_shop .changes_btn {
  background-color: #e84b56;
  color: #ffffff;
  width: 100%;
  height: 50px;
  font-size: 18px;
}
.create_shop .changes_btn:hover{
  background-color: #000;
}
.create_shop .changes_btn:focus {
  outline: none;
  box-shadow: none;
}
/* *************create shop end****** */
/* **************seller shop end*************** */
/* *******************Add Service****************** */
._strbrdr{
  border-radius:none;
  border: 2px solid #c0c0c0;
}
.add-service_modal {
  background: #ffffff;
  box-shadow: 0px 34px 100px rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  border: none;
  width: 100%;
  height: 550px;
  overflow-y: auto;
  opacity: 1;
}
.Add_service .choose_btn {
  background-color: #60e6e9;
  color: #ffffff;

  height: 50px;
}
.Add_service .choose_btn:focus {
  outline: none;
  box-shadow: none;
}
.Add_service .preview_btn {
  background-color: #33d197;
  color: #ffffff;
  width: 30%;
  height: 50px;
  line-height: 2.2;
}
.Add_service .preview_btn:focus {
  outline: none;
  box-shadow: none;
}
.srch_btn {
  background-color: #33d197;
  color: #ffffff;
  width: 100px;
  height: 40px;
  line-height: 1.2;
}
.srch_btn:focus {
  outline: none;
  box-shadow: none;
}
.Add_service .publish_btn {
  background-color: #e84b56;
  color: #ffffff;
  width: 30%;
  height: 50px;
}
.Add_service .publish_btn:hover{
  background-color: #000;
}
.active_btn {
  background-color: #33d197;
  border: none;
  color: #ffffff;
  width: 30%;
  height: 40px;
  margin-top: 10px;
    margin-bottom: 15px;
}
.active_btn:focus {
  outline: none;
  box-shadow: none;
}
.deactive_btn {
  background-color: #e84b56;
  border: none;
  color: #ffffff;
  width: 30%;
  height: 40px;
}
.deactive_btn:focus {
  outline: none;
  box-shadow: none;
}
.Add_service .publish_btn:focus {
  outline: none;
  box-shadow: none;
}
.Add_service .service-thamb {
  position: relative;
}
.Add_service .service-thamb .cross-btn {
  position: absolute;
  left: 30%;
  /* top: -22%; */
  bottom: 50%;
}
.Add_service h2{
  font-weight: bold;
  color: rgba(79, 25, 99, 1);
}
.Add_service label{
  color: rgba(79, 25, 99, 1);
  font-size: 18px;
  font-weight: 600;
}
/* **************order-report********** */
.order-report .report-btn {
  background-color: #33d197;
  color: #ffffff;
  width: 30%;
  height: 50px;
  line-height: 2.2;
}
/* ****************seller sec end********************* */

/* ***************wishlist**************** */
.wish_card {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgb(0 0 0 / 10%),
    0px 4.25733px 11.019px rgb(0 0 0 / 5%);
  border-radius: 20px;
  width: 100%;
  height: 450px;
  border: none;
}
.wish_card .web_img img {
  height: 200px;
  width: 240px;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}
.wish_card p {
  font-size: 12px;
  color: #000;
  word-break: break-all;
}
/* ***************wishlist end**************** */
/* ******************checkout***************** */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: none;
}
.list-group-item div img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  object-position: top;
}
.checkout_btn {
  background-color: #e84b56;
  color: #ffffff;
  width: 100%;
  height: 50px;
}
.lh-condensed h6 {
  width: 100%;
  max-width: 150px;
}

/* **********blank product****************** */
.blank_product h4 {
  margin: 1em 0;
  font-size: 2.5rem;
}
.blank_product h4 span {
  color: #3d3e3f;
  font-size: 1.5rem;
}
/* **********blank product end****************** */
/* *************privacy policy****************** */
.privacy p {
  color: #0a0e17;
}
/* *************privacy policy****************** */
/* *************cookies policy************** */
.cookies p {
  color: #0a0e17;
}
.return-policy p {
  color: #0a0e17;
}
.condition p {
  color: #0a0e17;
}
.after-verify h3 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0a0e17;
}
.after-verify p {
  font-size: 1.5rem;
  color: #0a0e17;
}
/* *************cookies policy************** */
/* ********feedback*************** */
.feedback h2{
  color: #a024b7;
}
.feedback .submit_btn {
  width: 30%;
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 1em;
  height: 50px;
}
.submit_btn:hover{
  background: #000;
}
/* ********new product on home*************** */
.new_product_design button {
  /* background: #a024b7; */
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  min-width: 96px;
  line-height: 22px;
  color: #ffffff;
}
.new_product_design .product_list_text h6 {
  font-size: 18px;
    color: #a024b7;
    height: 20px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
/* .new_product_design .rating {
  font-size: 92%;
} */
.new_product_design {
  min-height: 360px;
}
@media screen and (min-width: 1600px){
  .banner .slider_ban{
    height: 80vh;
  }
}
@media (max-width: 1200px) {
  .blog_ban_overlay {
    position: absolute;
    top: 0%;
    bottom: 21%;
  }
  .Footer_clip {
    /* padding-top: 17em; */
    background-size: cover;
  }
}
@media (max-width: 1110px) {
  .provider_banner_card {
    position: absolute;
    top: 26%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 10;
  }
  .Provider_service_list {
    margin-top: 474px;
  }
  .blog_ban_overlay {
    position: absolute;
    top: 0%;
    bottom: 21%;
  }
}
@media (max-width: 1024px) {
  .provider_banner_card {
    position: absolute;
    top: 19%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 10;
  }
  .Provider_service_list {
    margin-top: 436px;
  }
  .blog_ban_overlay {
    position: absolute;
    top: 0%;
    bottom: 27%;
    right: 3%;
    left: 3%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -18.9%,
      rgba(0, 0, 0, 0.67) 100%
    );
    border-radius: 10px;
  }
  .blog_ban_overlay h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    color: #ffffff;
  }
  .mixed-chart {
    position: relative;
    left: 10%;
  }
}
@media (max-width: 768px) {
  .Seller_Profile .balance_card,
.balance_card{
  width: 100%;
}
  .Provider_service_list {
    margin-top: 436px;
  }
  .provider_banner_card .card {
    background: #ffffff;
    box-shadow: 0px 34px 88px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border: none;
    height: 400px;
    overflow: scroll;
  }
  .about_ban_img {
    position: static;
    top: 0%;
    bottom: 0%;
    right: 0%;
    left: 0%;
  }
  .Spell .spell_menu {
    background: #ffffff;
    border-radius: 20px;
  }
  .blog_ban_overlay {
    position: absolute;
    top: 0%;
    bottom: 48%;
  }
  .blog_ban_overlay h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    color: #ffffff;
  }
  .seller_LeftBar{
    width: 100%;
  }
}
@media (max-width: 756px) {
  .about_ban_img {
    position: static;
    top: 0%;
    bottom: 0%;
    right: 0%;
    left: 0%;
  }
  
  .related_services .slick-prev {
    left: 81%;
  }
  .related_services .slick-next {
    right: 4%;
  }

  .Details .heading h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #0a0e17;
  }
  .select.active {
    line-height: 27px;
  }
  .related_products .slick-prev {
    left: 83%;
  }
  .related_products .slick-next {
    top: -34px;
    right: 4%;
  }
  .blog_ban_overlay {
    right: 4%;
    left: 5%;
    bottom: 0%;
  }
  .blog_ban_overlay h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    color: #ffffff;
  }
  .blog-detail-head h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
  .shop h5 {
    font-size: 18px;
  }
  .Close_modal {
    margin: 0px;
  }
  .Footer_clip {
    /* padding-top: 13em; */
    background-size: cover;
  }
  .banner .slider_ban {
    height: 690px;
  }
  .mixed-chart {
    position: relative;
    left: -0%;
  }
  .mdb-datatable.dt-bootstrap4 .d-flex.w-100.justify-content-between {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .header-cart {
    position: absolute;
    top: 19px;
    right: 0;
  }
  .header-cart:nth-child(2) {
    right: 36%;
  }
  .header-cart:nth-child(3) {
    right: 25%;
  }
  .header-cart:nth-child(4) {
    right: 16%;
  }
  .banner .slider_ban {
    height: 35vh;
  }
  .slider_ban .slider_text {
    justify-content: flex-start;
    padding-top: 1rem;
  }
  .banner .slider_text h1 {
    line-height: 1.3;
    font-size: 14px;
    font-weight: 550;
  }
  .banner .slider_text p {
    font-size: 11px;
    font-weight: 300;
    line-height: 1.4;
  }
  .banner .slider_text button {
    font-size: 15px;
    line-height: 1.6;
    min-width: 110px;
  }
  ._searchE20_group ._searchE20_in {
    padding: 0 10px;
    width: 383px;
  }
  .contact_div_index {
    padding: 1.2rem 0;
  }
  .contact_div_index_text_div p {
    font-size: 15px;
    font-weight: 500;
  }
  .service_head h3 {
    font-size: 27px;
  }
  .faq_head_home h3 {
    font-size: 27px;
  }
  .Footer_clip {
    /* padding-top: 17em; */
    background-size: cover;
  }
  .blog_ban_img img {
    height: 425px;
  }
  .blog_ban_overlay {
    height: 425px;
  }
  .mixed-chart {
    position: relative;
    left: -0%;
  }
  .Details .details_sub_text > div:first-child {
    width: 100%;
  }
}
.sd {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
}

/* chat call css */

li.log_time {
  font-size: 75%;
  margin: 1rem auto;
  text-align: center;
  position: relative;
}

li.log_time:after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #eaeaea;
}

.log_time span:last-child {
  background: #fff4f4;
  position: relative;
  z-index: 1;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0;
}

/* *************video call modal*************** */
.modal-dialog.video-modal {
  position: relative;
  width: 100%;
  /* margin: 0.5rem; */
  pointer-events: none;
  max-width: 716px;
  object-fit: cover;
}
/* video */
._large_video {
  position: relative;
  border-radius: 13px;
  width: auto;
  height: clamp(250px, 62%, 370px);
}
._large_video video {
  width: 100%;
  height: 100%;
  max-height: 400px;
  /* border-radius: 8% 8% 0 0; */
  /* margin-top: 1em; */
}
._large_video img {
  width: 100px;
  height: 100%;
  max-height: 100px;
  /* padding-top: 1em; */
  top: 10px;
  left: 10px;
  background: #f9f9f9;
  position: absolute;
  border-radius: 50%;
}

._meet_act_btn {
  border: none;
  padding: 10px;
  height: 40px;
  margin: 0 4px;
}

._video_intro.d-flex {
  position: absolute;
  bottom: 5%;
  left: -5%;
}
._video_intro button {
  font-size: 0.85em;
  border-radius: 8px;
  background: #3a394354;
  color: #ffffff;
}
._large_video .video-img {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 150px;
  height: 150px;
}

._large_video .own-video {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 150px;
  height: 150px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  border-radius: 6px;
  box-shadow: 0 0 7px 2px #0000004f;
  border: 2px solid #89898973;
}
.videoModal {
  background: #0000001e;
}

._large_video .remote_video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
/* video */
/* *******************video call end********* */
@media screen and (max-width: 767px) {
  .banner .slick-next {
    right: 10px;
  }
  .banner .slick-prev {
    left: 10px;
  }
  .banner .slick-prev,
  .banner .slick-next {
    width: 26px;
    height: 30px;
  }
  .Provider {
    height: 600px;
    background-size: cover;
  }
  .provider_sec .slick-slide {
    padding: 0;
  }
  .profile h5 {
    font-size: 26px;
  }
}

@media screen and (max-width: 767px) {
  .banner .slick-prev,
  .banner .slick-next {
    display: none !important;
  }
  .add_new_serv {
    position: relative;
    right: 0;
  }
}
/* *********modal css*********** */
._modal_open {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
/* *******button width**** */
._btn_width {
  width: 15%;
}

h5.readMoreText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

h5.readLessText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: null;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.shop_listing table p {
  color: #000;
}
.shop_listing table td {
  vertical-align: middle;
}
.banner .slick-prev:before,
.banner .slick-next:before {
  content: "";
  color: #ffffff;
  border: 0;
}

@media screen and (max-width: 560px) {
  /* home service and spell casting */
  .service,
  .casting_area .casting_sec {
    width: 100%;
    box-shadow: none;
    min-height: auto;
    border: 1px solid #66666661;
  }
  .casting_area .casting_sec {
    padding-top: 0 !important;
  }
  .service .card-body,
  .casting_area .casting_sec .card-body {
    padding: 5px;
  }
  .service .service_part img,
  .casting_area .casting_text img {
    max-width: 48px;
    height: 48px;
    margin-bottom: 5px;
  }
  .service .service_part h5,
  .casting_area .casting_text h4 {
    font-size: 17px;
    line-height: 30px;
    margin: 0 0 !important;
    height: 30px;
  }
  .service .service_part p,
  .casting_area .casting_text p {
    font-size: 12px;
    line-height: 1.5;
    min-height: 36px;
    margin-bottom: 0px;
    -webkit-line-clamp: 2;
  }
  .service .service_part button,
  .casting_area .casting_text button {
    background: transparent;
    color: #a024b7;
    padding: 0;
    margin: 0 !important;
  }
  /* home blog */
  .blog_box2 {
    border-radius: 10px;
  }
  .blog_box2 .card-img-top {
    border-radius: 10px 10px 0 0;
  }
  .blog_text h5 {
    font-size: 20px;
    min-height: 52px;
  }
  .blog_box2 h6 {
    font-size: 14px;
    line-height: 1.2;
  }
  .blog_text p {
    font-size: 13px;
    min-height: 36px;
  }
  /* home provider */
  .bg_lineCover {
    border: 5px solid transparent;
  }
  .provider_sec .slider2_part .slider2_body img {
    height: 112px;
  }
  .provider_sec .slider2_body h5 {
    font-size: 17px;
  }
  .provider_sec .slider2_body button {
    font-size: 13px;
    line-height: 12px;
    min-width: 85px;
  }
  /* product */
  .new_product_design button span {
    display: none;
  }
  .new_product_design button i {
    display: block;
    font-size: 20px;
  }
  .service_list.new_product_design .service_list_img {
    height: 112px;
    width: 112px;
  }
  .new_product_design .product_list_text h6 {
    font-size: 14px;
    height: 32px;
  }
  .new_product_design button {
    font-size: 14px;
    min-width: 74px;
    padding: 2px 0;
    line-height: 22px;
  }
  .new_product_design {
    min-height: auto;
    border-radius: 10px;
  }
  .new_product_design .card-body {
    padding: 0.75rem;
  }
}

/* puja */
._borderRed{
  border: 2px solid #a024b7;
}
button._bgclose{
  background: rgba(79, 25, 99, 1);
  opacity: 1;
}
.img_arrow{
  width: 25px;
  height: auto;
  margin: auto;
  display: table;
  position: absolute;
  top: 60px;
  right: 238px;
}
._openbtn{
  background: #a024b7;
  border: none;
}
._openbtn:hover{
  background-color: #000;
}
.subscription{
  background-color: aliceblue;
}
.icons_white{
  color: #fff;
  font-size: 20px;
}
.icons_white:hover{
  color: #a024b7;
}
.align_grp{
  margin-bottom: 2rem;
}
.Details .slick-initialized .slick-slide{
  float:none;
  width: 70px !important;
}
.add-to-cart-btn{
  display: none;
}


.cutom-service-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service_list .service-list-custom p{
  font-size: 18px;
    color: #a024b7;
    height: 24px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 500;
}
.service-list-custom .pricing{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 50% 1;
}
.service_list .service-list-custom h5 {
  font-size: 14px;
  color: #6c7579;
}
.service-list-custom .rating{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 50% 1;
}
.service-list-custom .rating i {
  color: #f2c94c;
  width: 16px;
  height: 16px;
}
.service-list-custom .rating  h6{
  font-size: 14px;
  font-weight: 400;
}
p.sales-count{
  color:#a3aaba;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  margin-left: 12px;
  margin-right:12px;
}
@media screen and (max-width: 768px){
  .Details .slick-initialized .slick-slide{
    float: left;
    width: 70px !important;
  }
  .service_list .service_list_img{
    height: 112px;
    width: 112px;
  }
  .service_list .service-list-custom p{
    font-size: 14px;
    height: 32px;
  }
  .order_details .tip_box{
    width:100%;
  }
  
  .service_list.custom-mob-service-list{
    min-height: auto;
    border-radius: 10px;
  }
} 
@media screen and (max-width:560px){
  .service_list button span{
    display: none;
  }
  .custom-mob-service-list .red_btn{
    font-size: 14px;
    min-width: 74px;
    padding: 2px 0;
    line-height: 22px;
  }
  .service_list .service_list_text h4{
    font-size: 13px;
  }
  
}
@media screen and (max-width:360px){
  .Details .slick-initialized .slick-slide{
    float: left;
    width: 70px !important;
  }
  .service_list .service_list_img{
    height: 112px;
    width: 112px;
  }
  .service_list .service-list-custom h5{
    font-size: 13px;
  }
} 

/*---New Css Start---*/
/*---whislist & cart hover----*/
/*-------------New Site Map-----------*/
/* .site__Map{
  background-color: #f3f3f3;
} */
.site__mapheading{
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #0a0e17;
}
.site__Map .sitemap_Title{
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}
.site__Map .quick__Links{
  /* list-style: none; */
  margin: 0;
  padding: 0;
}
.site__Map .quick__Links li{
 
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  
  color: #212529;
}
.site__Map .quick__Links li a{
  color: #212529;
  text-decoration: none;
}
.site__Map .quick__Links li a:hover{
  color: #a024b7;
}
.foot_menu{
  text-decoration: none;
}


/*----Chat--------*/
.sellCust{
   display: flex;
   align-items: center;
   justify-content: space-around;
   margin: 20px 0;
}
.sellCust button{
  background-color: #a024b7;
  color: #fff;
  transition: all 0.3s;
  border: none;
  padding: 10px 20px;
}
/*-----Blog-----------*/
.view_btn{
  background-color: #a024b7;
  transition: all 0.3s;
  padding: 6px 40px;
  border-radius: 20px;
}
.view_btn span{
  color: #fff;
}
.page_top_section {
  background: #ededed;
  padding: 6rem 0;
  box-shadow: 0px -5px 27px 0px rgb(0 0 0 / 15%) inset;
}
.page_top {
  justify-content: center;
  align-items: center;
  display: flex;
}
.page_top_title {
  text-align: center;
}
.page_top_title h3 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #a024b7;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif !important;
}
.page_top_title p {
  margin-bottom: 0;
  font-size: 16px;
  color: #666;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif !important;
}
.suggested_section {
  background-color: rgba(79, 25, 99, 1);
  padding: 1rem 1rem;
}
.suggested_section ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
}
.suggested_li {
  color: white;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 1px;
}
.suggested_btn_div button {
  text-transform: capitalize;
  border: 2px solid transparent;
  background: #ededed;
  border-radius: 25px;
  color: black;
  margin-left: 1rem;
  transition: all 0.3s;
}
@media screen and (min-width: 1024px){
  .suggested_section ul {
    display: flex !important;
    text-align: unset;
}
.suggested_btn_div button {
  margin-left: 1rem;
  margin-top: 0px;
}
}
@media screen and (min-width: 768px){
  .suggested_section ul {
    display: block;
    text-align: center;
}
.suggested_btn_div button {
  margin-left: 5px;
  margin-top: 10px;
}
}
@media screen and (max-width: 768px){
  .blog-filter-stage .item{
      margin-bottom: 10px;
  }
  .latest_box{
    height: auto;
  }
}


.provider_card_text blockquote p{
  font-style: italic;
}
/*-Service Provides----*/
ul.serviceFilter{
  align-items: center;
  justify-content: space-between;
  
}
.suggested_btn_div select{
  text-transform: capitalize;
  border: 2px solid transparent;
  background: #ededed;
  border-radius: 25px;
  color: black;
  margin-left: 1rem;
  transition: all 0.3s;
  padding: 10px 20px;
}
@media screen and (max-width: 991px){
  ul.serviceFilter{
    display: block;
  }
  .suggested_btn_div select{
    margin-bottom: 15px;
  }
}


/*-------Product List-------*/
.Product-list h3{
  color: #a024b7;
  font-weight: 600;
}
.web_img{
  text-align: center;
  margin-bottom: 10px;
}
.wish_card p.product-para{
  height: 40px;
  overflow: hidden;
}
.wish_card .web_img img{
  width: 100%;
  border-radius: 20px 20px 0 0;
}
.wish_card p.service-wishlist-para{
  height: 40px;
  overflow: hidden;
}
.service-wishlist h3{
  color: #a024b7;
  font-weight: 600;
}
.wishlist-provider h3{
  color: #a024b7;
  font-weight: 600;
}
.provide-services h1{
  color: #a024b7;
  font-weight: 600;
}


/*----------Mouse hovger--------*/
/*---------Seller Contact Us Details---------*/
.mdb-datatable{
  overflow-x: scroll;
}

.bGimg_service-details {
  height: 230px;
  width: 100%;
  margin-bottom: 25px;
}

.bGimg_service-details h2 {
  font-size: 42px;
  line-height: 46px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bGimg_Service_Booking {
  height: 230px;
  width: 100%;
  margin-bottom: 25px;
}

.bGimg_Service_Booking .order_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #a024b7;;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.bGimg_My_Orders {
  height: 230px;
  width: 100%;
  margin-bottom: 25px;
}

.bGimg_My_Orders .order_head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #a024b7;;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.bGimg_My_Profile {
  height: 210px;
  width: 100%;
  margin-bottom: 25px;
}

.bGimg_My_Profile .bGimg_My_Profile_head h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #a024b7;;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.current-page-bg{
 background-color: #b5b5b5;
}
.center-loader{
  margin: auto;
}
